import { useState } from "react";
import CheckoutGroupAddUserForm from "./checkout-group.part.add-user-form";
import CheckoutGroupPartUserSection from "./checkout-group.part.user-section";
import { PaymentGroup, PaymentStatus, PaymentUser } from "./checkout.types";
import { LabelValue } from "../../../../types/label-value.types";
import AppFormSectionTitle from "../../../../components/form/app-form-section-title";
import { useTranslation } from "react-i18next";
import AppSelect from "../../../../components/form/base/app-select";
import Button from "../../../../components/button";
import './checkout-group.scss';

const usersMock: PaymentUser[] = [
    { name: 'John Mick', email: 'john.mick@email.ro', phone: '+456789', status: PaymentStatus.PENDING },
    { name: 'Desiree Golden', email: 'desiree.golden@email.ro', phone: '+456789', status: PaymentStatus.PAID },
    { name: 'Johnnie Walker', email: 'johnnie.walker@email.ro', phone: '+456789', status: PaymentStatus.PENDING },
    { name: 'Beatrice Todd', email: 'beatrice.todd@email.ro', phone: '+456789', status: PaymentStatus.PAID },
    { name: 'Josef Rollins', email: 'josef.rollins@email.ro', phone: '+456789', status: PaymentStatus.PENDING },
    { name: 'Captain Walker', email: 'captain.walker@email.ro', phone: '+456789', status: PaymentStatus.PAID },
];

const groupsMock: PaymentGroup[] = [
    { id: 1, name: 'Family', users: [usersMock[2], usersMock[5]] },
    { id: 2, name: 'College', users: [usersMock[0], usersMock[1], usersMock[2], usersMock[3]] },
    { id: 3, name: 'Soccer', users: [usersMock[0], usersMock[2], usersMock[4]] },
];

const groupOptions: LabelValue<number>[] = groupsMock.map(g => ({
    label: g.name, value: g.id
}));

const CheckoutGroup: React.FC = () => {
    const [showNewUserForm, setShowNewUserForm] = useState<boolean>(false);
    const [group, setGroup] = useState<PaymentGroup| null>();
    const [users, setUsers] = useState<PaymentUser[]>([]);
    const { t } = useTranslation();

    const onChangeGroupHandler = (groupId: number) => {
        const group = groupsMock.find(g => g.id === groupId);
        setGroup(group);
    };

    const onAddNewUserHandler = (user: PaymentUser) => {
        const groupUsers = group?.users || [];
        setGroup(null);
        setUsers(users => [...users, ...groupUsers, { ...user, status: PaymentStatus.PENDING }]);
        setShowNewUserForm(false);
    }

    return (
        <>
            <AppFormSectionTitle
                title="Group Payment"
                info={t('pages.login.form.info')}
            />
            {!showNewUserForm && (
                <div className="select-group-container flex-column flex-gap-10">
                    <span className="group-label">Search Group</span>
                    <div className="flex-row flex-align-space-between-center">
                        <AppSelect
                            value={group?.id}
                            placeholder="Search Group"
                            options={groupOptions}
                            onChange={onChangeGroupHandler}
                        />

                        <Button
                            label="New user"
                            onClick={() => setShowNewUserForm(true)}
                            theme="outline"
                        />
                    </div>
                </div>
            )}
            {showNewUserForm &&
                <CheckoutGroupAddUserForm
                    onAddNewUser={onAddNewUserHandler}
                    onCancel={() => setShowNewUserForm(false)}
                />}

            {group &&
                <CheckoutGroupPartUserSection users={group.users} amount={15} timeLeft={44315} />
            }

            {
                users &&
                <CheckoutGroupPartUserSection users={users} amount={15} timeLeft={44315} />
            }
        </>
    )
}

export default CheckoutGroup;