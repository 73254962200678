import { GiftCardPurchaseModel, NotificationType } from '../models';
import { ProcessId, TaskName } from '../processes/process.types';
import { CardDeliveryType } from '../types/card.types';

export enum SendOtpThrough {
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL'
}

export interface StartProcessRequestPayload {
  [ProcessId.REGISTER]: {
    firstName: string;
    lastName:	string;
    birthdate: string;      // #TODO date without time
    sendOtpThrough: SendOtpThrough;
    email?: string;
    userName?: string;      // #TODO these fields are optional, either one or the other
    phone?: string;         // pattern ^\+?[0-9]{10,15}$
  };
  [ProcessId.PROFILE_CONFIGURATION]: {},
  [ProcessId.SOCIAL_REGISTER]: {},
}

export interface ProcessActiveTaskResponse {
  id: number;
  processInstance: number;
  humanTaskId: number;
  active: boolean;
  task: TaskName;
}

export interface CompleteTaskRequestPayload {
  [TaskName.REGISTER]: {
    // dummy, not used
  },
  [TaskName.ADD_OTP]: {
    otp: string;            // pattern ^[0-9]{4}$
  };
  [TaskName.ADD_ADDRESS]: {
    address?: {
      addressLine1:	string;
      addressLine2?:	string;
      country:	string;
      city:	string;
      postalCode:	string;         // pattern: ^[0-9]{6}$
      latitude: number;
      longitude: number;
    };
    skipAddress?: boolean;
  };
  [TaskName.UPLOAD_ID]: {
    idFile?: {
      mime: '@file/jpeg' | '@file/png' | '@file/pdf';     // #TODO fix MIME Types
      data: string;
    };
    skipKyc?: boolean;
  },
  [TaskName.UPLOAD_SELFIE]: {
    selfieFile: {
      mime: '@file/jpeg' | '@file/png' | '@file/pdf';     // #TODO fix MIME Types
      data: string;
    }
  },
  [TaskName.ADD_PASSWORD]: {
    password: string;
    confirmPassword: string;          // #TODO confirmPassword shouldn't get to BE, it's just a FE validation
  }
}


export type CreateProductPayload = Omit<Product, 'id' | 'cartId' | 'orderId' | 'template'>

export interface Product {
  id: number;
  templateId: number;
  label: string;
  amount: number;
  companyId?: string;
  plannedDelivery?: string;
  deliveryType?: NotificationType;
  quantity: number;
  template: GiftCardPurchaseModel;
  recipientDetails?: string;
  senderDetails?: string;
  deliveryMessage?: string;
  userId: string;
  cartId: number;
  orderId?: number;
}
