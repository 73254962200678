import { Company } from '../../../types/merchant.types';

export const mockCompany: Company = {
  name: "Tech Innovations Ltd.",
  cui: "123456789",
  fiscalIdentity: "FI987654321",
  address: {
    street: "123 Innovation Drive",
    county: "Silicon Valley",
    city: "San Francisco",
    country: "USA",
    postalCode: "94107"
  },
  account: {
    iban: "US64SVBKUS6S3300958879",
    bankName: "Silicon Valley Bank"
  }
};