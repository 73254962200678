import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import './my-account-menu.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/store';
import { userSliceActions, UserState } from '../../store/user.slice';
import { NavigationState } from '../../store/navigation.slice';
import Button from '../../components/button';
import { localStorageService, StorageKey } from '../../services/local-storage.service';

export enum MyAccountPage {
  MY_PROFILE = 'my-account.menu.item.my-profile',
  MY_FRIENDS = 'my-account.menu.item.my-friends',
  MY_ORDERS = 'my-account.menu.item.my-orders',
  MY_DESIGNS = 'my-account.menu.item.my-designs',
  MY_CARDS = 'my-account.menu.item.my-cards',
  FAVORITES = 'my-account.menu.item.favorites',
}

const myAccountMenuItems = [
  {icon: 'user', label: MyAccountPage.MY_PROFILE, path: 'profile'},
  {icon: 'group', label: MyAccountPage.MY_FRIENDS, path: 'friends'},
  {icon: '3d-cube', label: MyAccountPage.MY_ORDERS, path: 'orders'},
  {icon: 'webpage', label: MyAccountPage.MY_DESIGNS, path: 'designs'},
  {icon: 'credit-card', label: MyAccountPage.MY_CARDS, path: 'cards'},
  {icon: 'favorite', label: MyAccountPage.FAVORITES, path: 'favorites'},
];

const MyAccountMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userState = useSelector<AppStore, UserState>(store => store.user);
  const navigationState = useSelector<AppStore, NavigationState>(store => store.navigation);
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await localStorageService.removeItem(StorageKey.ACCESS_TOKEN);
    dispatch(userSliceActions.removeUser());
    navigate('/');
  }
  
  return (
    <div className="my-account-menu">
      <div className="menu-user">
        <div className="profile-image"></div>
        <span className="user-name">{userState.user?.firstName + ' ' + userState.user?.lastName}</span>
        {userState.user?.verified && (
          <span className="verified">V</span>
        )}
      </div>

      {myAccountMenuItems.map((item, idx) => (
        <Fragment key={item.label}>
          <div
            className={`menu-item ${navigationState.accountPageLoaded === item.label ? 'active' : ''}`}
            onClick={() => navigate(item.path)}
          >
            <span className={`menu-item-icon app-icon app-icon-${item.icon}`}></span>
            <span className="label">{t(item.label)}</span>
            <span className="app-icon app-icon-arrow-right"></span>
          </div>

          {idx < (myAccountMenuItems.length - 1) && (
            <div className="separator" key={`separator-${idx}`}></div>
          )}
        </Fragment>
      ))}
      <Button theme='outline' label='Logout' onClick={handleLogout}/>
      </div>
  );
};

export default MyAccountMenu;