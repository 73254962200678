import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';
import MyProfilePage from './pages/my-profile/my-profile-page';
import MyDesignsPage from './pages/my-profile/my-designs-page';

export const myAccountRouter: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='profile' replace={true} />,
  },
  {
    path: 'profile',
    element: <MyProfilePage />
  },
  {
    path: 'designs',
    element: <MyDesignsPage />
  }
];
