export enum GiftCardTheme {
  LIGHT = 'light',
  DARK = 'dark',
  ACCENT = 'accent'

}

export interface GiftCardModel {
  id?: number;
  theme?: GiftCardTheme;
  backgroundColor?: string | null;
  backgroundImage?: string | null;
  smallImage?: string | null;
  text?: string;
}

export enum CardPurchaseType {
  PREPAID = 'PREPAID',
  GIFT = 'GIFT',
  CUSTOM = 'CUSTOM'
}

export interface GiftCardPurchaseModel extends GiftCardModel {
  type: CardPurchaseType;
  title?: string;
  priceRange?: string;
  parentId?: string;
  occasion?: string;
}
  
  export enum GiftCardType {
    PHYSICAL = 'PHYSICAL',
    VIRTUAL = 'VIRTUAL',
  }
  
  export enum NotificationType {
    WHATSAPP = 'WHATSAPP',
    EMAIL = 'EMAIL'
  }
  
  export interface GiftCardPurchaseDetails {
    giftCardType: GiftCardType;
    merchant: string;
    value: number;
    quantity: number;
    cardMessage: string;
    notification: {
      via: NotificationType;
      to: string;
      from: string;
      message: string;
      notificationDateTime: string;
    };
  }
