import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, Control, FieldPath, FieldValues } from 'react-hook-form';
import './app-form-location.scss';

const gpsLocationIcon = require('../../assets/images/gps-location-icon.png');

export interface Coords {
  latitude: number;
  longitude: number;
}

interface Validations {
  required: string | boolean;
}

interface AppFormLocationProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
  control: Control<TForm>;
  name: TName;
  label: string;
  validations?: Partial<Validations>;
}

const AppFormLocation = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
  control,
  name,
  label,
  validations,
}: AppFormLocationProps<TForm, TName>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<Coords | null>(null);
  const { t } = useTranslation();

  const getLocationHandler = async (field: any) => {
    setIsLoading(true);
    try {
      const fetchedLocation = await mockLocationService();
      setLocation(fetchedLocation);
      field.onChange(fetchedLocation);
    } catch (error) {
      console.error('Failed to get location:', error);
    } finally {
      field.onBlur();
      setIsLoading(false);
    }
  };

  return (
    <div className="app-form-element-container">
      <div className="form-label-container">
        <label className="form-label-text">
          {label}
          {validations?.required && <span className="error-text">*</span>}
        </label>
      </div>

      <Controller
        name={name}
        control={control}
        rules={validations}
        render={({ field, fieldState }) => (
          <>
            <div className="form-row">
              <div className="input-group">
                <div className="form-input-container">
                  <input
                    type="text"
                    readOnly
                    className="form-input"
                    placeholder={t('forms.field.latitude.label')}
                    value={location ? location.latitude.toString() : ''}
                    onBlur={field.onBlur}
                  />
                </div>

                <div className="form-input-container">
                  <input
                    type="text"
                    readOnly
                    className="form-input"
                    placeholder={t('forms.field.longitude.label')}
                    value={location ? location.longitude.toString() : ''}
                  />
                </div>
              </div>

              <button
                type="button"
                className={`gps-location-icon-container ${isLoading ? 'disabled' : ''}`}
                onClick={() => !isLoading && getLocationHandler(field)}
              >
                <img src={gpsLocationIcon} alt="GPS Icon" className="gps-location-icon" />
              </button>
            </div>

            {fieldState.error && (
              <span className="form-element-error-text">
                {fieldState.error.message || 'Error'}
              </span>
            )}
          </>
        )}
      />
    </div>
  );
};

const mockLocationService = async () => {
  return new Promise<Coords>((resolve) => {
    setTimeout(() => {
      resolve({ latitude: 51.5074, longitude: -0.1278 }); 
    }, 1000);
  });
};

export default AppFormLocation;
