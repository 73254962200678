import React, { memo } from 'react';
import "./form-wrapper.scss";
import Button from './button';

type Props = {
  title?:string,
  content: React.ReactNode,
  submitBtnLabel: string,
  isSubmitDisabled?: boolean,
  onSubmit?: () => void,
  onCancel?: () => void,
  className?: string,
}

function MemoComponent({
   title,
   content,
   submitBtnLabel,
   isSubmitDisabled,
   onSubmit,
   onCancel,
   className
}: Props) {
  /*
  * NOTE TO DEVS:
  * use "form-row" class on each row within the form-content
  * use "half-row-input" class within the row for 2 equals inputs
  * there are responsive styles for them
  * */
  return (
    <div className={`form-wrapper ${className}`}>
      <div className="flex-row flex-align-space-between-center mb-30">
        { title && <span className="form-title display-block">{title}</span> }
        { onCancel && <div className="cancel-btn" onClick={onCancel}>Cancel</div> }
      </div>


      <div className="form-content">
        { content }
      </div>

      <Button
        className="submit-btn mt-30"
        disabled={isSubmitDisabled}
        label={submitBtnLabel}
        theme="outline"
        onClick={onSubmit}
      />
    </div>
  )
}

export const FormWrapper = memo(MemoComponent);