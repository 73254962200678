import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';
import { MerchantProfile } from './pages/merchant-profile/merchant-profile';
import { MerchantUsers } from './pages/merchant-users/merchant-users';
import { MerchantShops } from './pages/merchant-shops/merchant-shops';
import { MerchantTemplates } from './pages/merchant-templates/merchant-templates';
import { MerchantStatistics } from './pages/merchant-statistics/merchant-statistics';

export const merchantRouter: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='profile' replace={true} />,
  },
  {
    path: 'profile',
    element: <MerchantProfile />
  },
  {
    path: 'users',
    element: <MerchantUsers />
  },
  {
    path: 'shops',
    element: <MerchantShops />
  },
  {
    path: 'templates',
    element: <MerchantTemplates />
  },
  {
    path: 'statistics',
    element: <MerchantStatistics />
  }
];
