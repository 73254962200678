import { Toast } from 'primereact/toast';

class ErrorService {
    toast: Toast | null = null;

  constructor() {
    this.toast = null;
  }

  setToastRef(toastRef: Toast | null) {
    this.toast = toastRef;
  }

  showApiError(error: any, title = 'Something went wrong!') {
    let text;

    if (error.response?.data) {
      if (error.response.data?.error_description) {
        text = error.response.data.error_description;
      } else {
        text = JSON.stringify(error.response.data);
      }
    } else if (typeof error === 'string') {
      text = error;
    } else if (error instanceof Error) {
      text = error.message;
    } else {
      text = JSON.stringify(error);
    }

    this.showError(title, text);
  }

  showError(title: string, text?: string) {
    if (this.toast) {
      this.toast.show({
        severity: 'error',
        summary: title,
        detail: text,
        life: 1000,
      });
    }
  }
}

const errorService = new ErrorService();
export { errorService };
