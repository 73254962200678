import { memo } from 'react';
import { GiftCardModel } from '../../models';
import { TemplateCard } from '../template-card';
import './templates-table.scss';

type Props = {
  cards: GiftCardModel[],
  onSelect?: (card: GiftCardModel) => void
}

function MemoComponent({ cards, onSelect }: Props) {
  return (
    <div className="flex-row flex-gap-30 flex-wrap flex-align-center-center">
      {cards?.map((card) => (
        <div key={card.id}>
          <TemplateCard
            template={card}
            onSelect={onSelect}
          />
        </div>
      ))}
    </div>
  );
}

export const TemplatesTable = memo(MemoComponent);