import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TemplatesTable } from '../../../../components/templates-table/templates-table';
import { TransactionsTable } from '../../../../components/transactions-table/transactions-table';
import { mockTransactions } from '../../mockData/mock-transactions';
import { apiService } from '../../../../services/api.service';
import { GiftCardModel } from '../../../../models';
import "./merchant-templates.scss";

function MemoComponent() {
  const {  merchantId } = useParams();
  const [templates, setTemplates] = useState<GiftCardModel[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<GiftCardModel>();
  const [title, setTitle] = useState<string>('Card List');

  useEffect(() => {
    if (merchantId) {
      apiService.getTemplatesByUserId(merchantId).then((response) => {
        setTemplates(response.data);
      })
    }
  }, [merchantId])

  useEffect(() => {
    setTitle(selectedTemplate ? 'Template Details' : 'Template List');
  }, [selectedTemplate]);

  return (
    <div className="merchant-templates">
      <div className="flex-row flex-align-space-between-center title-container mb-20">
        <span className="title display-block">{title}</span>
        {selectedTemplate && (
          <span className="go-back-btn display-block" onClick={() => setSelectedTemplate(undefined)}>
            Back to list
          </span>
        )}
      </div>

      <TemplatesTable
        cards={selectedTemplate ? [ selectedTemplate ] : templates}
        onSelect={selectedTemplate ? undefined : setSelectedTemplate}
      />

      {selectedTemplate && (
        <div className="mt-20">
          <div className="subtitle">Orders</div>
          <TransactionsTable transactions={mockTransactions}/>
        </div>
      )}
    </div>
  );
}

export const MerchantTemplates = memo(MemoComponent);