import { useEffect, useState } from 'react';
import { navigationSliceActions } from '../../../../store/navigation.slice';
import { MyAccountPage } from '../../my-account-menu';
import './my-designs-page.scss'
import { useDispatch, useSelector } from 'react-redux';
import DashboardPageTitle from '../../../../components/dashboard-page-title';
import { useTranslation } from 'react-i18next';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import { apiService } from '../../../../services/api.service';
import GiftCard from '../../../../components/gift-card';
import { GiftCardModel, GiftCardPurchaseModel } from '../../../../models/gift-card.model';



const MyDesignsPage = () => {
    const [cards, setCards] = useState<GiftCardModel[]>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userState = useSelector<AppStore, UserState>((store) => store.user);
    useEffect(() => {
        const getMyDesigns = async () => {
            dispatch(navigationSliceActions.setAccountPage(MyAccountPage.MY_DESIGNS));
            try {
            const response = await apiService.getTemplatesByUserId(userState.user?.id!)
            const modifiedDesigns = response.data.map((design) => ({
                ...design,
                smallImage: design.smallImage,
                backgroundImage: design.backgroundImage
                    
            }));
            return modifiedDesigns;
            } catch (e) {

            }
        }
        getMyDesigns().then((myDesignsList => {
            setCards(myDesignsList);
        }))
    }, [userState.user?.id]);


    return (
        <div className="my-designs-page">
            <DashboardPageTitle text={t('pages.my-designs.title')}></DashboardPageTitle>
            <div className="my-designs-page-content flex-wrap">
                <h2 className='subtitle'>Card designs</h2>
                <div className='flex-row flex-wrap flex-gap-25'>
                    {cards?.map((card) => (
                        <div className='flex-column flex-end flex-gap-20' key={card.id}>
                            <GiftCard card={card} showQrCode={false}></GiftCard>
                            <div className='flex-row flex-align-end-end flex-gap-30'>
                            <span
                                className="action-button"
                            >
                                <span>{t('layout.buttons.edit')}</span>
                                <span className="app-icon app-icon-edit"></span>
                            </span>
                            <span
                                className="action-button"
                            >
                                <span>Delete</span>
                                <span className="app-icon app-icon-edit"></span>
                            </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MyDesignsPage;