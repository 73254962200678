import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import AppFormInput from '../../../../components/form/app-form-input';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { processService } from '../../../../processes/process.service';
import { TaskName } from '../../../../processes/process.types';
import { ProcessState } from '../../../../store/process.slice';
import { useTranslation } from 'react-i18next';
import { errorService } from '../../../../services/error.service';

export interface PasswordFormValue extends FieldValues {
  password: string;
  confirmPassword: string;
}

const RegisterPageStepPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<PasswordFormValue>();
  const processState = useSelector<AppStore, ProcessState>(store => store.process);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const form = useForm<PasswordFormValue>({
    mode: 'onTouched',
    defaultValues: {}
  });

  const onSubmitHandler = async (data: PasswordFormValue) => {
    setFormValue(data);
  }

  const onConfirmTnC = async () => {
    setIsLoading(true);

    try {
      await processService.completeTask<TaskName.ADD_PASSWORD>(
        processState,
        formValue!,
        navigate,
        dispatch,
        true
      );

      navigate('/account-created');
    } catch (e) {
      errorService.showApiError(e);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppFormSectionTitle
        title={formValue ? t('pages.register.step.terms.form.title') : t('pages.register.step.password.form.title')}
        info={formValue ? t('pages.register.step.terms.form.info') : t('pages.register.step.password.form.info')}
        optional={!formValue}
      ></AppFormSectionTitle>

      {!formValue && (
        <>
          <AppFormInput
            name="password"
            control={form.control}
            label={t('forms.field.password.label')}
            validations={{ required: true }}
            type="password"
          />
          <AppFormInput
            name="confirmPassword"
            control={form.control}
            label={t('forms.field.password-confirm.label')}
            validations={{ required: true }}
            type="password"
          />
        </>
      )}

      {formValue && (
        <p className="form-element-width" style={{ marginBottom: 22 }}>
          These Terms and Conditions establish the guidelines for using our services and applications. By accessing or utilizing our services, you agree to adhere to these terms.

          Users must comply with all applicable laws and regulations when using our services. They are also responsible for maintaining the security of their account credentials.

          We do not guarantee that the services will be available at all times. We reserve the right to suspend or discontinue the services at any time without notice.

          Our liability to users is limited to the maximum extent permitted by law. We are not responsible for any indirect, incidental, or consequential damages arising from the use of our services.

          We reserve the right to modify these Terms and Conditions at any time. Continued use of the services after changes have been made constitutes acceptance of the new terms.

          For any questions or concerns regarding these Terms and Conditions, please contact us at: hello@bloomafrica.net.
        </p>
      )}

      <div className="form-buttons-container">
        <Button
          label={formValue ? t('layout.buttons.agree-continue') : t('layout.buttons.set-new-password')}
          onClick={formValue ? onConfirmTnC : form.handleSubmit(onSubmitHandler)}
          theme="accent"
          disabled={!formValue && !form.formState.isValid}
        ></Button>
      </div>
    </>
  );
};

export default RegisterPageStepPassword;