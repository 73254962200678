import { useTranslation } from "react-i18next"
import AppFormSectionTitle from "../../../../components/form/app-form-section-title"
import { useForm } from "react-hook-form";
import AppFormInput from "../../../../components/form/app-form-input";
import Button from "../../../../components/button";
import './checkout-group.part.add-user-form.scss';
import { PaymentStatus, PaymentUser } from "./checkout.types";

interface CheckoutGroupPartSideMenuFormValue {
    name: string;
    email: string;
    phone: string;
}


interface CheckoutGroupAddUserFormProps {
    onAddNewUser: (newUser: PaymentUser) => void | Promise<void>;
    onCancel: () => void | Promise<void>;
}

const CheckoutGroupAddUserForm: React.FC<CheckoutGroupAddUserFormProps> = ({onAddNewUser, onCancel}) => {
    const { t } = useTranslation();
    const form = useForm<CheckoutGroupPartSideMenuFormValue>({
        mode: 'onTouched',
        defaultValues: {}
    });

    const onSubmit = (data: CheckoutGroupPartSideMenuFormValue) => {
        onAddNewUser({...data, status: PaymentStatus.PENDING})

    }
    return (
    <div className="checkout-group-form-container">
        
        <AppFormInput
            name="name"
            control={form.control}
            label="Full Name"
            validations={{ required: true }}
        />
        <AppFormInput
            name="email"
            control={form.control}
            label={t('forms.field.email.label')}
            validations={{ required: true, minLength: 5 }}
            type="email"
        />
        <AppFormInput
            name="phone"
            control={form.control}
            label={t('forms.field.phone-number.label')}
            validations={{ required: true, minLength: 5 }}
            type="text"
        />
        <Button
            theme="outline"
            label='Add user'
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}/>
    </div>)
}

export default CheckoutGroupAddUserForm;