import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiService } from '../../../../services/api.service';
import { TemplateCard } from '../../../../components/template-card';
import Button from '../../../../components/button';
import CustomDesignPageStepCustomize from '../../../public/pages/create-custom-design/custom-design.step-customize';
import InfoPanel from '../../../public/pages/create-custom-design/custom-design-page.part.info-panel';
import { PaginationWrapper } from '../../../../components/pagination-wrapper';
import { GiftCardModel } from '../../../../models';

const ITEMS_PER_PAGE = 6;

function MemoComponent() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<GiftCardModel[]>([]);
  const [createFlow, setCreateFlow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getAllTemplates = async () => {
    setIsLoading(true);
    const response = await apiService.getAllCardTemplates({
      page: currentPage,
      size: ITEMS_PER_PAGE
    });
    setTemplates(response.data?.content);
    setTotalPages(response.data?.totalPages);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTemplates();
  }, [currentPage]);

  const onCreate = useCallback(() => {
    setCurrentPage(1);
    setCreateFlow(!createFlow);
  }, [createFlow]);

  const onSelectPage = (newPage: number) => setCurrentPage(newPage);

  return (
    <div className="templates-page">
      <div className="flex-row flex-align-end-center mb-30">
        <Button label={createFlow ? t("layout.buttons.cancel") : t("layout.buttons.create")} onClick={onCreate} />
      </div>

      { !createFlow && (
        <PaginationWrapper
          currentPage={currentPage}
          onSelectPage={onSelectPage}
          totalPages={totalPages}
          disabled={isLoading}
        >
          <div className="flex-row flex-gap-30 flex-wrap flex-align-center-center">
            {templates?.map((template) => (
              <TemplateCard key={template.id} template={template} />
            ))}
          </div>
        </PaginationWrapper>
      )}

      {createFlow && (
        <>
          <div className="custom-design-page-content-container">
            <CustomDesignPageStepCustomize firstStepOnly onSuccess={() => setCreateFlow(false)}/>
          </div>
          <InfoPanel/>
        </>
      )}
    </div>
  );
}

export const TemplatesPage = memo(MemoComponent);