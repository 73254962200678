import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import AppFormSectionTitle from '../../../../components/form/app-form-section-title';
import AppFormInput from '../../../../components/form/app-form-input';
import Button from '../../../../components/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { processService } from '../../../../processes/process.service';
import { TaskName } from '../../../../processes/process.types';
import { ProcessState } from '../../../../store/process.slice';
import { useTranslation } from 'react-i18next';
import AppFormLocation from '../../../../components/form/app-form-location';
import { errorService } from '../../../../services/error.service';

export interface AddressFormValue extends FieldValues {
  addressLine1: string;
  addressLine2?: string;
  city:	string;
  country:	string;
  postalCode:	string;
}

const RegisterPageStepAddress: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const processState = useSelector<AppStore, ProcessState>(store => store.process);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const form = useForm<AddressFormValue>({
    mode: 'onTouched',
    defaultValues: {}
  });

  const onSubmitHandler = async (data: AddressFormValue) => {
    setIsLoading(true);

    try {
      await processService.completeTask<TaskName.ADD_ADDRESS>(processState, {address: {
        ...data, latitude: 2, longitude: 5
        }}, navigate, dispatch);
      await processService.startNextTask(processState.inProgress?.jobId, navigate, dispatch);
    } catch (e) {
      errorService.showApiError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onSkipHandler = async () => {
    setIsLoading(true);

    try {
      await processService.completeTask<TaskName.ADD_ADDRESS>(processState, {skipAddress: true}, navigate, dispatch);
      await processService.startNextTask(processState.inProgress?.jobId, navigate, dispatch);
    } catch (e) {
      errorService.showApiError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <AppFormSectionTitle
        title={t('pages.register.step.address.form.title')}
        optional={true}
      ></AppFormSectionTitle>

      <AppFormInput
        name="addressLine1"
        control={form.control}
        label={t('forms.field.address-line-1.label')}
        validations={{required: true}}
      />
      <AppFormInput
        name="addressLine2"
        control={form.control}
        label={t('forms.field.address-line-2.label')}
      />
      <AppFormInput
        name="city"
        control={form.control}
        label={t('forms.field.city.label')}
        validations={{required: true}}
      />
      <AppFormInput
        name="country"
        control={form.control}
        label={t('forms.field.country.label')}
        validations={{required: true}}
      />
      <AppFormInput
        name="postalCode"
        control={form.control}
        label={t('forms.field.postal-code.label')}
        validations={{required: true}}
      />
      <AppFormLocation
        name="location"
        control={form.control}
        label={t('forms.field.gps-location-recommended.label')}
        // validations={{required: true}}
      />

      <div className="form-buttons-container">
        <Button
          label={t('layout.buttons.next-step')}
          onClick={form.handleSubmit(onSubmitHandler)}
          theme="accent"
          disabled={!form.formState.isValid}
        ></Button>

        <Button
          label={t('layout.buttons.skip-for-now')}
          onClick={onSkipHandler}
          theme="outline"
        ></Button>
      </div>
    </>
  );
};

export default RegisterPageStepAddress;