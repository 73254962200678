import { Employee } from '../../types/merchant.types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRow } from './user-row/user-row';
import { GenericTable } from '../generic-table/generic-table';
import "./users-table.scss";

type Props = {
  users: Employee[],
  onSelect: (user: Employee) => void,
};

function MemoComponent({ users, onSelect }: Props) {
  const { t } = useTranslation();
  const columns = [
    { label: t("forms.field.user-name.label"), className: "cell-username" },
    { label: `${t("forms.field.email.label")} &`,
      secondLineLabel: t("forms.field.phone-whatsapp.label"),
      className: "cell-contact hide-xs"
    },
    { label: t("merchant.menu.item.date-added"), className: "cell-date-added hide-sm" },
    { label: t("shop.shop-assigned"), className: "cell-assigned-shop hide-sm" },
    { label: '', className: "cell-actions" },
  ];

  return (
    <GenericTable
      className="users-table"
      columns={columns}
      rows={
        <>
          {users.map((user) => (
            <UserRow user={user} key={user.id} onSelect={onSelect} />
          ))}
        </>
      }
    />
  );
}

export const UsersTable = memo(MemoComponent);