import React from 'react';
import './app-form-readonly.scss';

interface AppFormReadonlyProps {
  label: string;
  value?: string | number | null;
  secondLineValue?: string | number | null;
  className?: string;
}

const AppFormReadonly: React.FC<AppFormReadonlyProps> = (
  {label, value, secondLineValue, className}
) => {
  return (
    <div className={`app-form-readonly ${className}`}>
      <span className="label">{label}</span>
      <span className="value">{value}</span>
      { secondLineValue && <span className="value">{secondLineValue}</span> }
    </div>
  );
}

export default AppFormReadonly;
