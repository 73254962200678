import React from 'react';
import './button-icon.scss';
import { ForwardedProps } from '../types/component-props.types';

interface ButtonIconProps extends ForwardedProps {
  theme?: 'accent' | 'secondary-accent'| 'outline' | 'outline-light';
  icon: string;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
}

const ButtonIcon: React.FC<ButtonIconProps> = (
  {theme = 'accent', icon, disabled, label, onClick, className}
) => {
  return (
    <button
      className={`button-icon ${theme} ${className || ''}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`app-icon app-icon-${icon}`}></span>
      {label}
    </button>
  );
};

export default ButtonIcon;