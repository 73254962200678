import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Merchant } from '../../../types/merchant.types';
import { dateService } from '../../../services/date.service';
import { mockEmployees } from '../../../router/merchant/mockData';
import { merchantSliceActions } from '../../../store/merchant.slice';
import { useDispatch } from 'react-redux';

function MemoComponent({ merchant }: { merchant: Merchant }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { screenWidth } = useOutletContext<{ screenWidth: number }>();
  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    if (screenWidth >= 960) {
      setIsExtended(false);
    }
  }, [screenWidth]);

  const onToggleRow = () => {
    if (screenWidth < 960) {
      setIsExtended(!isExtended);
    }
  }

  const openDetails = (event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    dispatch(merchantSliceActions.saveMerchant(merchant));
    navigate(`/merchant/${merchant.companyId}/profile`);
  }

  return (
    <div className="merchant-row">
      <div className="t-row" onClick={onToggleRow}>
        <div className="t-cell cell-merchant">
          <span>{merchant.companyName}</span>
        </div>
        <div className="t-cell cell-shops hide-sm">
          <span>{merchant.noOfShops || 0}</span>
        </div>
        <div className="t-cell cell-users hide-sm">
          <span>{merchant.noOfUsers || 0}</span>
        </div>
        <div className="t-cell cell-templates hide-sm">
          <span>{merchant.noOfCards || 0}</span>
        </div>
        <div className="t-cell cell-manager hide-xs">
          <span>{merchant.managerFullName || "-"}</span>
        </div>
        <div className="t-cell cell-date hide-xs">
          <span>{dateService.formatBirthDate(merchant.date) || "-"}</span>
        </div>
        <div className="t-cell cell-actions flex-row flex-align-end-center flex-gap-10">
          <div onClick={openDetails}>
            <span>{t('merchant.menu.item.details')}</span>
            <span className="app-icon app-icon-search-plus"/>
          </div>
          <div className="">
            <span>{t('layout.buttons.delete')}</span>
            <span className="app-icon app-icon-trash"/>
          </div>
        </div>
      </div>

      { isExtended && (
        <div className="more-details flex-row flex-align-space-between-start flex-gap-25 mb-20">
          <div className="flex-50">
            <div className="mb-10">
              <span className="bolded-text">{`${t('merchant.menu.item.shops')}: `}</span>
              <span>{merchant.noOfShops || 0}</span>
            </div>

            <div className="mb-10">
              <span className="bolded-text">{`${t('merchant.menu.item.users')}: `}</span>
              <span>{merchant.noOfUsers || 0}</span>
            </div>

            <div className="mb-10">
              <span className="bolded-text">{`${t('merchant.menu.item.templates')}: `}</span>
              <span>{merchant.noOfCards || 0}</span>
            </div>
          </div>

          <div className="flex-50">
            <div className="manager mb-10">
              <span className="bolded-text">{`${t("merchant.menu.item.manager")}: `}</span>
              <span>{merchant.managerFullName || "-"}</span>
            </div>

            <div className="date-added">
              <span className="bolded-text">{`${t("merchant.menu.item.date-added")}: `}</span>
              <span>{dateService.formatBirthDate(merchant.date) || "-"}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export const MerchantRow = memo(MemoComponent);