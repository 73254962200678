import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../types/merchant.types';
import DashboardFormSection from './dashboard-form-section';
import AppFormReadonly from './form/app-form-readonly';
import DashboardSectionContainer from './dashboard-section-container';
import AppFormInput from './form/app-form-input';
import { useForm } from 'react-hook-form';

type Props = {
  company: Company
}

function MemoComponent({ company }: Props) {
  const { t } = useTranslation();

  const details = [
    { label: t("forms.field.company-name.label"), value: company.name },
    { label: t("forms.field.cui.label"), value: company.cui },
    { label: t("forms.field.fiscal-identity.label"), value: company.fiscalIdentity },
    {
      label: t("forms.field.address.label"),
      value: `${company.address.street}, ${company.address.city}, ${company.address.country}, ${company.address.postalCode}`
    },
    { label: t("forms.field.iban.label"), value: company.account.iban, secondLine: company.account.bankName },
  ];

  const companyForm = useForm<Company>({
    mode: 'onTouched',
    defaultValues: company
  });

  const onSubmitCompany = useCallback((data: Company) => {
    console.log('saving company: ', data);
  }, []);


  const Documents = memo(() => (
    <section>
      <div className="mb-20">
        <span className="form-label display-block mb-5">{t('forms.field.registration-document.label')}</span>
        <span className="value display-block">registration.doc</span>
      </div>

      <div>
        <span
          className="form-label display-block mb-5">{t('forms.field.tax-identification-number-document.label')}</span>
        <span className="value display-block">registration.doc</span>
      </div>
    </section>
  ));

  return (
    <DashboardSectionContainer>
      <DashboardFormSection
        title={t('admin.account.documents.title')}
        isEditable={false}
        readonlyView={
          <div className="flex-column flex-gap-25">
            <Documents />

            <DashboardFormSection
              title={t('admin.account.company.title')}
              onSave={companyForm.handleSubmit(onSubmitCompany)}
              formIsValid={companyForm.formState.isValid}
              readonlyView={
                <div>
                  {details.map((detail) => (
                    <AppFormReadonly
                      key={detail.label}
                      label={detail.label}
                      value={detail.value}
                      secondLineValue={detail.secondLine}
                      className="mb-20"
                    />
                  ))}
                </div>
              }
              editView={
                <>
                  <AppFormInput
                    name="name"
                    control={companyForm.control}
                    label={t('forms.field.company-name.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="cui"
                    control={companyForm.control}
                    label={t('forms.field.cui.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="fiscalIdentity"
                    control={companyForm.control}
                    label={t('forms.field.fiscal-identity.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="address.street"
                    control={companyForm.control}
                    label={t('forms.field.address-line-1.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="address.city"
                    control={companyForm.control}
                    label={t('forms.field.city.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="address.country"
                    control={companyForm.control}
                    label={t('forms.field.country.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="address.postalCode"
                    control={companyForm.control}
                    label={t('forms.field.postal-code.label')}
                    validations={{ required: true }}
                  />

                  <AppFormInput
                    name="account.iban"
                    control={companyForm.control}
                    label={t('forms.field.iban.label')}
                    validations={{ required: true, minLength: 16, maxLength: 16 }}
                  />

                  <AppFormInput
                    name="account.bankName"
                    control={companyForm.control}
                    label={t('forms.field.bank-name.label')}
                    validations={{ required: true }}
                  />
                </>
              }
            />
          </div>
        }
      />
    </DashboardSectionContainer>
  );
}

export const AccountDocumentsCard = memo(MemoComponent);