import { Employee } from '../../../types/merchant.types';

export const mockEmployees: Employee[] = [
  {
    id: '1',
    firstName: "John",
    lastName: "Smith",
    phoneNumber: "+1234567891",
    email: "john.smith@example.com",
    assignedManagerId: "MGR123456",
    account: {
      iban: "DE89370400440532013001",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop1",
    employmentDate: "2024-01-01T00:00:00Z",
  },
  {
    id: '2',
    firstName: "Alice",
    lastName: "Johnson",
    phoneNumber: "+1234567892",
    email: "alice.johnson@example.com",
    assignedManagerId: "MGR123457",
    account: {
      iban: "DE89370400440532013002",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop2",
    employmentDate: "2024-02-15T12:30:00Z",
  },
  {
    id: '3',
    firstName: "Michael",
    lastName: "Williams",
    phoneNumber: "+1234567893",
    email: "michael.williams@example.com",
    assignedManagerId: "MGR123458",
    account: {
      iban: "DE89370400440532013003",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop3",
    employmentDate: "2024-03-20T08:15:45Z",
  },
  {
    id: '4',
    firstName: "Emily",
    lastName: "Brown",
    phoneNumber: "+1234567894",
    email: "emily.brown@example.com",
    assignedManagerId: "MGR123459",
    account: {
      iban: "DE89370400440532013004",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop4",
    employmentDate: "2024-04-25T14:45:30Z",
  },
  {
    id: '5',
    firstName: "David",
    lastName: "Jones",
    phoneNumber: "+1234567895",
    email: "david.jones@example.com",
    assignedManagerId: "MGR123460",
    account: {
      iban: "DE89370400440532013005",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop5",
    employmentDate: "2024-05-30T10:05:15Z",
  },
  {
    id: '6',
    firstName: "Sarah",
    lastName: "Garcia",
    phoneNumber: "+1234567896",
    email: "sarah.garcia@example.com",
    assignedManagerId: "MGR123461",
    account: {
      iban: "DE89370400440532013006",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop1",
    employmentDate: "2024-06-10T16:20:00Z",
  },
  {
    id: '7',
    firstName: "James",
    lastName: "Martinez",
    phoneNumber: "+1234567897",
    email: "james.martinez@example.com",
    assignedManagerId: "MGR123462",
    account: {
      iban: "DE89370400440532013007",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop2",
    employmentDate: "2024-07-04T18:40:30Z",
  },
  {
    id: '8',
    firstName: "Jessica",
    lastName: "Hernandez",
    phoneNumber: "+1234567898",
    email: "jessica.hernandez@example.com",
    assignedManagerId: "MGR123463",
    account: {
      iban: "DE89370400440532013008",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop3",
    employmentDate: "2024-08-22T09:50:10Z",
  },
  {
    id: '9',
    firstName: "William",
    lastName: "Lopez",
    phoneNumber: "+1234567899",
    email: "william.lopez@example.com",
    assignedManagerId: "MGR123464",
    account: {
      iban: "DE89370400440532013009",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop4",
    employmentDate: "2024-09-18T13:35:50Z",
  },
  {
    id: '10',
    firstName: "Megan",
    lastName: "Gonzalez",
    phoneNumber: "+1234567800",
    email: "megan.gonzalez@example.com",
    assignedManagerId: "MGR123465",
    account: {
      iban: "DE89370400440532013010",
      bankName: "Deutsche Bank"
    },
    assignedShopId: "shop5",
    employmentDate: "2024-10-05T07:25:40Z",
  }
];