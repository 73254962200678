import React, { memo, useCallback, useEffect } from 'react';
import { Employee } from '../types/merchant.types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import AppFormInput from './form/app-form-input';
import { FormWrapper } from './form-wrapper';
import AppFormDropdown from './form/app-form-dropdown';
import { mockShops } from '../router/merchant/mockData';

const defaultUser: Employee = {
  id: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  assignedManagerId: '',
  account: {
    iban: '',
    bankName: ''
  },
  assignedShopId: '',
  employmentDate: ''
};

type Props = {
  user?: Employee,
  onSave: (data: Employee) => void,
  onCancel: () => void,
  className?: string,
}

function MemoComponent({ user = defaultUser, onSave, onCancel, className }: Props) {
  const { t } = useTranslation();
  const editMode = !!user.id;
  const title = editMode ? t("forms.field.edit-user.label") : t("forms.field.add-new-user.label");
  const submitBtnLabel = editMode ? t("layout.buttons.save") : `+ ${t("forms.field.add-new-user.label")}`;

  const form = useForm<Employee>({
    mode: 'onTouched',
    defaultValues: user
  });

  useEffect(() => {
    form.reset(user);
  }, [user, form]);

  const onSubmit = useCallback((data: Employee) => {
    console.log('save user: ', data);
    onSave(data);
  }, [onSave]);

  const clearForm = useCallback(() => {
    form.reset(defaultUser);
    onCancel();
  }, [onCancel, form]);

  return (
    <FormWrapper
      title={title}
      submitBtnLabel={submitBtnLabel}
      isSubmitDisabled={!form.formState.isValid}
      onSubmit={() => form.formState.isValid && form.handleSubmit(onSubmit)()}
      onCancel={form.formState.isDirty || editMode ? clearForm : undefined}
      className={`user-form ${className}`}
      content={
        <>
          <div className="form-row">
            <AppFormInput
              name="firstName"
              control={form.control}
              label={t('forms.field.first-name.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
          </div>

          <div className="form-row">
            <AppFormInput
              name="lastName"
              control={form.control}
              label={t('forms.field.last-name.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
            <AppFormInput
              name="phoneNumber"
              control={form.control}
              label={t('forms.field.phone-number.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
          </div>

          <div className="form-row" style={{alignItems: "flex-start"}}>
            <AppFormInput
              name="email"
              control={form.control}
              label={t('forms.field.email.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
            <AppFormDropdown
              name="assignedShopId"
              control={form.control}
              options={mockShops.map(item => ({ label: item.name, value: item.id }))}
              label={t('shop.shop-assigned')}
              placeholder={t('shop.shop-assigned')}
              className="half-row-input"
            />
          </div>
        </>
      }
    />
  );
}

export const UserForm = memo(MemoComponent);