import React, { memo, useCallback, useEffect } from 'react';
import { Shop } from '../types/merchant.types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AppFormInput from './form/app-form-input';
import { FormWrapper } from './form-wrapper';

const defaultShop: Shop = {
  id: '',
  name: '',
  address: {
    street: '',
    county: '',
    city: '',
    country: '',
    postalCode: '',
    latitude: 0,
    longitude: 0
  },
  dateAdded: '',
  account: {
    iban: '',
    bankName: ''
  },
  managerId: '',
};

type Props = {
  shop?: Shop,
  onSave: (data: Shop) => void,
  onCancel: () => void,
  className?: string,
}

function MemoComponent({ shop = defaultShop, onSave, onCancel, className }: Props) {
  const { t } = useTranslation();
  const editMode = !!shop.id;
  const title = editMode ? t("forms.field.edit-shop.label") : t("forms.field.add-new-shop.label");
  const submitBtnLabel = editMode ? t("layout.buttons.save") : `+ ${t("forms.field.add-new-shop.label")}`;

  const form = useForm<Shop>({
    mode: 'onTouched',
    defaultValues: shop
  });

  useEffect(() => {
    form.reset(shop);
  }, [shop, form]);

  const onSubmit = useCallback((data: Shop) => {
    console.log('saving shop: ', data);
    onSave(data);
  }, [onSave]);

  const clearForm = useCallback(() => {
    form.reset(defaultShop);
    onCancel();
  }, [onCancel, form]);

  return (
		<FormWrapper
			title={title}
			submitBtnLabel={submitBtnLabel}
			isSubmitDisabled={!form.formState.isValid}
			onSubmit={() => form.formState.isValid && form.handleSubmit(onSubmit)()}
      onCancel={form.formState.isDirty || editMode ? clearForm : undefined}
      className={`shop-form ${className}`}
      content={
        <>
          <div className="form-row">
            <AppFormInput
              name="name"
              control={form.control}
              label={t('forms.field.shop-name.label')}
              validations={{ required: true }}
              className="half-row-input"
            />

            <AppFormInput
              name="name"
              control={form.control}
              label={t('forms.field.shop-photo.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
          </div>

          <div className="form-row">
            <AppFormInput
              name="address.street"
              control={form.control}
              label={t('forms.field.address-line-1.label')}
              validations={{ required: true }}
              className="half-row-input"
            />

            <AppFormInput
              name="address.country"
              control={form.control}
              label={t('forms.field.country.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
          </div>

          <div className="form-row">
            <AppFormInput
              name="address.city"
              control={form.control}
              label={t('forms.field.city.label')}
              validations={{ required: true }}
              className="half-row-input"
            />

            <AppFormInput
              name="address.postalCode"
              control={form.control}
              label={t('forms.field.postal-code.label')}
              validations={{ required: true }}
              className="half-row-input"
            />
          </div>

          <div className="form-row">
            <AppFormInput
              name="address.county"
              control={form.control}
              label={t('forms.field.county.label')}
              validations={{ required: true }}
              className="half-row-input"
            />

            <div className="half-row-input flex-row flex-gap-25 flex-align-space-between-end">
              <AppFormInput
                name="address.latitude"
                control={form.control}
                label={t('forms.field.gps-location.label')}
                placeholder={t('forms.field.latitude.label')}
                className="flex-50"
              />
              <AppFormInput
                name="address.longitude"
                control={form.control}
                label=''
                placeholder={t('forms.field.longitude.label')}
                className="flex-50"
              />
            </div>
          </div>
        </>
      }
    />
  )
}

export const ShopForm = memo(MemoComponent);