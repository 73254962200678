import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  CompleteTaskRequestPayload,
  CreateProductPayload,
  ProcessActiveTaskResponse,
  Product,
  StartProcessRequestPayload,
} from './api.service.types';
import { ProcessId, TaskName } from '../processes/process.types';
import { attachAxiosLogInterceptors, attachAxiosApiInterceptors } from '../utils/axios-utils';
import { GiftCardModel, GiftCardPurchaseModel, UserModel } from '../models';
import { Dispatch } from '@reduxjs/toolkit';
import { CartItem } from './cart.service.types';

class ApiService {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      // #TODO add to config
      baseURL: 'https://gateway.bloom.realgrowsoft.com',
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json',
      }
    });

    attachAxiosLogInterceptors(this.client);
  }

  async startProcess<T extends ProcessId>(
    processId: T,
    data: StartProcessRequestPayload[T]
  ): Promise<AxiosResponse<number>> {
    const res: AxiosResponse<string> = await this.client.post<string>(
      `/zeebe-broker/startProcess/${processId}`,
      data
    );

    return { ...res, data: +res.data };
  }

  getActiveTask(jobId: number): Promise<AxiosResponse<ProcessActiveTaskResponse>> {
    return this.client.get(`/zeebe-broker/${jobId}/active`);
  }

  completeTask<T extends TaskName>(
    taskName: T,
    humanTaskId: number,
    data: CompleteTaskRequestPayload[T]
  ): Promise<AxiosResponse<void>> {
    return this.client.post(
      `/zeebe-broker/completeUserTask/${taskName}/${humanTaskId}`,
      data
    );
  }

  attachDispatchToAxiosApi(dispatch: Dispatch<any>) {
    attachAxiosApiInterceptors(this.client, dispatch)
  }

  getCompaniesDropdown() {
    return this.client.post('/auth/groups/companies/all/dropdown');
  }

  getAllCompanies({ page = 0, size = 100, sort = []}) {
    return this.client.post(`/auth/groups/companies/all?page=${page}&size=${size}`, { page, size, sort });
  }

  getUserDetails(userId: string): Promise<AxiosResponse<UserModel>> {
    return this.client.get(`/auth/${userId}`)

  }

  postWhatsAppInvite(phoneNumber: string) {
    this.client.post(`/core/notifications/sendWhatsappMessage/NEW_LINK_MESSAGE`, {
      receiverPhoneNumber: phoneNumber,
      newMemberLink: "https://web.bloom.realgrowsoft.com/"
    });
  }

  postEmailInvite(email: string) {
    this.client.post(`/core/notifications/sendWhatsappMessage/NEW_LINK_MESSAGE`, {
      receiverEmail: email,
      message: "https://web.bloom.realgrowsoft.com/"
    });
  }

  createTemplate(customDesign: Omit<GiftCardPurchaseModel, 'id'>): Promise<GiftCardPurchaseModel> {
    return new Promise<GiftCardPurchaseModel>((resolve, reject) => {
      this.client.post('/card/template/createTemplate', customDesign)
        .then((result) => {
          console.log(result);
          debugger;
          if (result.status < 200 || result.status > 299) {
            reject(result);
          } else {
            resolve(result.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getTemplatesByUserId(userId: string): Promise<AxiosResponse<GiftCardModel[]>> {
    return this.client.get(`/card/template/${userId}`)
  }

  getAllCardTemplates({ page, size }: { page?: number, size?: number }): Promise<AxiosResponse<any>> {
    return this.client.get(`/card/template/getAll?page=${page}&size=${size}`);
  }

  createProduct(data: CreateProductPayload): Promise<AxiosResponse<string>> {
    return this.client.post(
      '/core/products/createProduct',
      data,
    );
  }

  updateProductQuantity(productId: number, quantity: number) {
    return this.client.put(`core/products/updateProduct/${productId}`, { quantity })
  }

  getCart(userId: string): Promise<AxiosResponse<CartItem>> {
    return this.client.get(`/core/cart/getCartByUserId/${userId}`);
  }

  addPromoCoreToCart(cardId: number, code: string) {
    return this.client.put(`/core/promoCodes/addPromoCodeToCart/${cardId}/${code}`);
  }

  removeFromCart(itemId: number): Promise<AxiosResponse<void>> {
    return this.client.delete(`/core/products/deleteProduct/${itemId}`);
  }

  createOrder(order: CartItem) {
    return this.client.post('core/orders/createOrder', order)
  }

}

const apiService = new ApiService();
export { apiService };