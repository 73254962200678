import { memo, useState } from 'react';
import { mockEmployees } from '../../mockData';
import { UsersTable } from '../../../../components/users-table';
import { Employee } from '../../../../types/merchant.types';
import { UserForm } from '../../../../components/user-form';

function MemoComponent() {
  const [selectedUser, setSelectedUser] = useState<Employee>();

  return (
    <div className="tab-card-container">
      <UserForm
        user={selectedUser}
        onSave={() => setSelectedUser(undefined)}
        onCancel={() => setSelectedUser(undefined)}
      />
      <UsersTable users={mockEmployees} onSelect={setSelectedUser} />
    </div>
  )
}

export const MerchantUsers = memo(MemoComponent)