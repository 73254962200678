import { Shop } from '../../../types/merchant.types';

export const mockShops: Shop[] = [
  {
    id: "shop1",
    name: "Tech World",
    address: {
      street: "123 Tech Street",
      county: "Tech County",
      city: "Tech City",
      country: "Techland",
      postalCode: "12345",
      latitude: 37.7749,
      longitude: -122.4194
    },
    dateAdded: "2022-01-15",
    managerId: '1',
    account: {
      iban: "SHOP1234567890",
      bankName: "Tech Bank"
    }
  },
  {
    id: "shop2",
    name: "Gadget Galaxy",
    address: {
      street: "456 Gadget Avenue",
      county: "Gadget County",
      city: "Gadget City",
      country: "Gadgetland",
      postalCode: "23456",
      latitude: 34.0522,
      longitude: -118.2437
    },
    dateAdded: "2023-02-20",
    managerId: '4',
    account: {
      iban: "SHOP2345678901",
      bankName: "Gadget Bank"
    }
  },
  {
    id: "shop3",
    name: "Device Depot",
    address: {
      street: "789 Device Road",
      county: "Device County",
      city: "Device City",
      country: "Deviceland",
      postalCode: "34567",
      latitude: 40.7128,
      longitude: -74.0060
    },
    dateAdded: "2023-05-10",
    managerId: '5',
    account: {
      iban: "SHOP3456789012",
      bankName: "Device Bank"
    }
  },
  {
    id: "shop4",
    name: "Accessory Avenue",
    address: {
      street: "101 Accessory Lane",
      county: "Accessory County",
      city: "Accessory City",
      country: "Accessoryland",
      postalCode: "45678",
      latitude: 51.5074,
      longitude: -0.1278
    },
    dateAdded: "2023-08-05",
    managerId: '10',
    account: {
      iban: "SHOP4567890123",
      bankName: "Accessory Bank"
    }
  },
  {
    id: "shop5",
    name: "Gizmo Garage",
    address: {
      street: "202 Gizmo Blvd",
      county: "Gizmo County",
      city: "Gizmo City",
      country: "Gizmoland",
      postalCode: "56789",
      latitude: 48.8566,
      longitude: 2.3522
    },
    dateAdded: "2023-11-15",
    managerId: '9',
    account: {
      iban: "SHOP5678901234",
      bankName: "Gizmo Bank"
    }
  }
]
