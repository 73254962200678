import { Employee } from '../../../types/merchant.types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mockShops } from '../../../router/merchant/mockData';
import { dateService } from '../../../services/date.service';
import { useOutletContext } from 'react-router-dom';

type Props = {
  user: Employee,
  onSelect: (user: Employee) => void,
  className?: string
};

function MemoComponent({ user, className, onSelect }: Props) {
  const { t } = useTranslation();
  const { screenWidth } = useOutletContext<{ screenWidth: number }>();
  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    if (screenWidth >= 960) {
      setIsExtended(false);
    }
  }, [screenWidth]);

  const onToggleRow = () => {
    if (screenWidth < 960) {
      setIsExtended(!isExtended);
    }
  }

  const getShopById = useCallback((id: string | undefined) => (
    mockShops.find(item => item.id === id)
  ), []);

  return (
    <div className={`user-row ${className}`}>
      <div className="t-row" onClick={onToggleRow}>
        <div className="t-cell cell-username">
          <span className="display-block mb-5">{user.firstName}</span>
          <span className="display-block">{user.lastName}</span>
        </div>
        <div className="t-cell cell-contact hide-xs">
          <span className="display-block mb-5">{user.email}</span>
          <span className="display-block">{user.phoneNumber}</span>
        </div>
        <div className="t-cell cell-date-added hide-sm">
          <span>{dateService.formatBirthDate(user.employmentDate)}</span>
        </div>
        <div className="t-cell cell-assigned-shop hide-sm">
          <span>{getShopById(user.assignedShopId)?.name}</span>
        </div>
        <div className="t-cell cell-actions flex-row flex-align-end-center flex-gap-10">
          <div onClick={() => onSelect(user)}>
            <span>{t('layout.buttons.edit')}</span>
            <span className="app-icon app-icon-edit"></span>
          </div>
          <div>
            <span>{t('layout.buttons.delete')}</span>
            <span className="app-icon app-icon-trash"/>
          </div>
        </div>
      </div>

      { isExtended && (
        <div className="more-details">
          <div className="mb-10">
            <span className="bolded-text">{`${t("merchant.menu.item.date-added")}: `}</span>
            <span>{dateService.formatBirthDate(user.employmentDate)}</span>
          </div>

          <div className="mb-10">
            <span className="bolded-text">{`${t("shop.shop-assigned")}: `}</span>
            <span>{getShopById(user.assignedShopId)?.name}</span>
          </div>

          <div className="contact mb-10">
            <span className="bolded-text">{`${t("forms.field.phone-whatsapp.label")}: `}</span>
            <span>{user.phoneNumber}</span>
          </div>

          <div className="contact mb-10">
            <span className="bolded-text">{`${t("forms.field.email.label")}: `}</span>
            <span>{user.email}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export const UserRow = memo(MemoComponent);