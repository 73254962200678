import React, { memo, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/page-title';
import { useTranslation } from 'react-i18next';
import { MerchantMenu } from './merchant-menu';
import { merchantSliceActions, MerchantState } from '../../store/merchant.slice';
import "./merchant-layout.scss";
import { AppStore } from '../../store/store';
import { apiService } from '../../services/api.service';
import { Merchant } from '../../types/merchant.types';

function MemoComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {  merchantId } = useParams();
  const merchantState = useSelector<AppStore, MerchantState>(store => store.merchant);
  const merchant = merchantState.merchant;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!merchant) {
      apiService.getAllCompanies({}).then((response) => {
        const selectedMerchant = response.data.find((merchant: Merchant) => merchant.companyId === merchantId);
        if (selectedMerchant) {
          dispatch(merchantSliceActions.saveMerchant(selectedMerchant));
        } else {
          navigate('/');
          return;
        }

      })
    }
  }, [merchant, merchantId, dispatch, navigate]);

  return (
    <div className="merchant-layout page-outer">
      <div className="page-content">
        <PageTitle text={t('my-account.layout.title')}></PageTitle>

        <div className="merchant-router">
          <MerchantMenu />
          <div className="merchant-page">
            <Outlet context={{ screenWidth }}/>
          </div>
        </div>
      </div>
    </div>
  );
}

const MerchantLayout = memo(MemoComponent);
export default MerchantLayout;