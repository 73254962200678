import { useEffect, useState } from "react";
import { CardPurchaseType, GiftCardPurchaseModel, GiftCardTheme, GiftCardType, NotificationType, UserModel } from "../../../../models";
import GiftCard from "../../../../components/gift-card";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AppFormDropdown from "../../../../components/form/app-form-dropdown";
import AppFormCounterInput from "../../../../components/form/app-form-counter-input";
import LineSeparator from "../../../../components/line-separator";
import AppFormRadioGroup from "../../../../components/form/app-form-radio-group";
import AppFormInput from "../../../../components/form/app-form-input";
import "./custom-design.step-card-value.scss"
import Button from "../../../../components/button";
import AppFormSectionTitle from "../../../../components/form/app-form-section-title";
import CustomFormRadioGroup from "./custom-form-radio-group";
import AppFormTextArea from "../../../../components/form/app-form-textarea";
import { AppStore } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { CustomDesignState } from "../../../../store/custom-design.slice";
import { useNavigate } from "react-router-dom";
import { LabelValue } from "../../../../types/label-value.types";
import { formatPrice } from "../../../../utils/number.utils";
import { shoppingCartActions } from "../../../../store/shopping-card.slice";
import SwitchToggle from "../../../../components/switch-toggle";
import { CreateProductPayload } from "../../../../services/api.service.types";
import { UserState } from "../../../../store/user.slice";
import { apiService } from "../../../../services/api.service";
import { errorService } from "../../../../services/error.service";

interface CardMessage {
    selectedOption: string,
    dropdownValue?: string,
    inputValue?: string
}

export interface CustomCardValueFormValue extends FieldValues {
    giftCardType: GiftCardType;
    address?: string;
    merchant?: string;
    value?: number;
    customValue?: number;
    quantity: number;
    cardMessage?: CardMessage;
    notificationType: NotificationType,
    whatsAppNumber?: string;
    notificationTo?: string;
    notificationFrom?: string;
    notificationMessage?: string;
}

const merchants = [
    { label: 'Prada Shop', value: 'prada' },
    { label: 'Boss Shop', value: 'boss' },
    { label: 'Lacoste Shop', value: 'lacoste' },
    { label: 'Carrefour Shop', value: 'carrefour' }
]

const CustomDesignPageStepCardValue = () => {

    const [activeToggle, setActiveToggle] = useState<GiftCardType>(GiftCardType.VIRTUAL);

    const [customCard, setCustomCard] = useState<GiftCardPurchaseModel>({
        theme: GiftCardTheme.DARK,
        backgroundColor: '#ECECEC',
        backgroundImage: "",
        title: "",
        type: CardPurchaseType.CUSTOM,
        smallImage: ""
    });

    const views = [{
        type: GiftCardType.PHYSICAL,
        name: "Mail (Plastic Gift Card)",
    }, {
        type: GiftCardType.VIRTUAL,
        name: "Email (eGift Card)",
    }];
    const customDesignState = useSelector<AppStore, CustomDesignState>(store => store.customDesign);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const cardValueOptions: LabelValue<number>[] = [50000, 100000, 200000, 500000].map(value => ({
        label: formatPrice(value),
        value: value
    }));

    const userState = useSelector<AppStore, UserState>(state => state.user);

    const { t } = useTranslation();
    const form = useForm<CustomCardValueFormValue>({
        mode: 'onTouched',
        defaultValues: {
            quantity: 1,
            cardMessage: {
                selectedOption: 'dropdown',
                dropdownValue: 'A gift for you'
            }
        }
    });

    const notificationType = useWatch({
        control: form.control,
        name: 'notificationType'
    })

    const cardMessage = useWatch({
        control: form.control,
        name: 'cardMessage'
    })

    


    useEffect(() => {
        setCustomCard(prevValue => (
            {
                ...prevValue,
                text: cardMessage?.selectedOption && cardMessage?.selectedOption === 'input' ? cardMessage.inputValue : cardMessage?.dropdownValue ? cardMessage.dropdownValue : "",
            }
        ))

    }, [cardMessage])

    useEffect(() => {
        setCustomCard(prevValue => ({
            ...prevValue,
            ...customDesignState.cardTemplate
        }
        ))

        if (customDesignState?.cardValue?.merchant) {
            const selectedMerchant = merchants.find((merchant) => merchant.value == customDesignState?.cardValue?.merchant);

            if (selectedMerchant)
                form.setValue('merchant', selectedMerchant.value)
        }
    }, [customDesignState]);


    const changeDesignPressed = () => {
        navigate('/custom-design')
    }
    const changeToggle = (value: GiftCardType) => {
        setActiveToggle(value);

        if (value === GiftCardType.VIRTUAL) {

            form.setValue('address', '');

        } else {

            form.setValue('address', '');

        }
    }

    
    const onAddToCartHandler = async (data: CustomCardValueFormValue) => {

        if (!userState.user) {
            return navigate('/login');
          }
        
        const payload: CreateProductPayload = {
            userId: userState.user?.id,
            templateId: customDesignState?.cardTemplate!.id!,
            amount: customCard.type === CardPurchaseType.PREPAID  ? data.customValue! : data.value! ,
            // #TODO get the list of companies
            // companyId: data.merchantId,
            quantity: data.quantity,
            label: data.cardMessage?.selectedOption == 'input' ? data.cardMessage?.inputValue! : data.cardMessage?.dropdownValue!,
            deliveryMessage: data.notificationMessage,
            deliveryType: data.notificationType,
            senderDetails: data.notificationFrom,
            // plannedDelivery: data.notificationDateTime,
            recipientDetails: data.notificationTo,
          };
          try {
          await apiService.createProduct(payload);
        } catch (e) {
            errorService.showApiError(e)
        } finally {
            navigate('/checkout');
        }
    };

    const onContinueShopping = () => {
        navigate('/e-gift-cards')
    }


    return (<>
        <div className="custom-design-item section1">
            <div className="flex-column flex-gap-30 flex-align-center-center">
                <GiftCard card={customCard} />
                <Button
                    label={t('pages.custom-design.step.card-value.button.change-design')}
                    theme="outline"
                    onClick={changeDesignPressed}
                />
            </div>
            <AppFormSectionTitle
                title={t('pages.custom-design.step.card-value.section.title.card-message')}
                info={t('pages.custom-design.step.card-value.section.info.card-message')}
                required={true}
            />
            <CustomFormRadioGroup
                control={form.control}
                name="cardMessage"
                dropdownOptions={[
                    { value: "A gift for you", label: "A gift for you" },
                    { value: "Grateful for you", label: "Grateful for you" },
                    { value: "Thank you", label: "Thank you" },
                    { value: "Wishing you joy", label: "Wishing you joy" },
                    { value: "A special treat", label: "A special treat" },
                    { value: "Happy shopping", label: "Happy shopping" }
                ]}
                validations={{ maxLength: { value: 50, message: '50 Character Max' } }}
                dropdownText={t('pages.custom-design.step.card-value.form.card-message.dropdownText')}
                inputPlaceholder={t('pages.custom-design.step.card-value.form.card-message.inputPlaceholder')}
                inputText={t('pages.custom-design.step.card-value.form.card-message.inputText')}
            />
        </div>
        <div className="custom-design-item section2">
            <div className="flex-column flex-gap-20">
                {customCard.type !== CardPurchaseType.GIFT &&
                    <>
                        <SwitchToggle elements={views} activeElement={activeToggle} onChange={changeToggle}></SwitchToggle>
                        {activeToggle === GiftCardType.PHYSICAL ? (<>
                            <p className="responsize-text">Address</p>
                            <AppFormInput
                                name="address"
                                control={form.control}
                                label=""
                                placeholder="Address:"
                            /></>) : <></>}
                    </>}
                {customCard.type === CardPurchaseType.GIFT && (<>
                    <p className="responsize-text">{t('pages.custom-design.step.card-value.form.merchant.title')}</p>
                    <AppFormDropdown
                        control={form.control}
                        name="merchant"
                        options={merchants}
                    />
                </>
                )
                }
                <div className="flex-row flex-1 card-value-quantity-container">
                    <div className="flex-column flex-gap-20 flex-50 card-value-container">
                        <p>{t('pages.custom-design.step.card-value.form.card-value.title')}</p>
                        {customCard.type === CardPurchaseType.PREPAID ?
                            <AppFormInput
                                type="number"
                                name="customValue"
                                control={form.control}
                                label=""
                                validations={{ required: true }}

                            /> :
                            <AppFormDropdown
                                control={form.control}
                                name="value"
                                options={cardValueOptions}
                                validations={{ required: true }}
                            />

                        }
                        <span className="helper-text">
                            {t('pages.custom-design.step.card-value.form.card-value.helper-text')}
                        </span>

                    </div>
                    <div className="flex-column flex-gap-20 flex-50 flex-align-start-end card-quantity-container">
                        <AppFormCounterInput
                            control={form.control}
                            name="quantity"
                            min={1}
                            max={100}
                            label={t('pages.custom-design.step.card-value.form.quantity.label')}
                            step={1}
                        />
                    </div>
                </div>
            </div>
            <LineSeparator className="separator-color" />
            <div className="flex-column flex-gap-10 gift-message-container">
                <AppFormSectionTitle
                    title={t('pages.custom-design.step.card-value.form.gift-message-type.section.title')}
                    info={t('pages.custom-design.step.card-value.form.gift-message-type.section.info')}
                />
                <AppFormRadioGroup
                    name="notificationType"
                    control={form.control}
                    options={[{
                        value: NotificationType.EMAIL,
                        text: 'Email',
                    }, {
                        value: NotificationType.WHATSAPP,
                        text: 'Whatsapp number',
                    }]}
                />
            </div>
            <div className="flex-row flex-gap-20 email-inputs-container">
                {notificationType === NotificationType.EMAIL ?
                    <>
                        <AppFormInput
                            name="notificationTo"
                            control={form.control}
                            label={t('pages.custom-design.step.card-value.form.receiver-email.label')}
                            placeholder={t('pages.custom-design.step.card-value.form.receiver-email.placeholder')}
                        />

                        <AppFormInput
                            name="notificationFrom"
                            control={form.control}
                            label={t('pages.custom-design.step.card-value.form.sender-email.label')}
                            placeholder={t('pages.custom-design.step.card-value.form.sender-email.placeholder')}
                        />
                    </>
                    : <AppFormInput
                        name="whatsAppNumber"
                        control={form.control}
                        label={t('forms.field.phone-number.label')}
                        validations={{ minLength: 5 }}
                        type="text"
                    />}
            </div>
            <AppFormTextArea
                control={form.control}
                name="giftMessage"
                label={t('pages.custom-design.step.card-value.form.text-area.label')}
                placeholder={t('pages.custom-design.step.card-value.form.text-area.placeholder')}
                validations={{ maxLength: { value: 50, message: '50 Character Max' } }}
                rows={5}
            />
            <div className="flex-row flex-align-end-end flex-gap-30 buttons-container">
                <Button 
                    label={t('pages.custom-design.step.card-value.button.shopping')} 
                    theme="outline" 
                    onClick={onContinueShopping}/>
                <Button 
                    label={t('pages.custom-design.step.card-value.button.cart')}
                    onClick={form.handleSubmit(onAddToCartHandler)} 
                    disabled={!form.formState.isValid}
                 />
            </div>
        </div>
    </>)

}

export default CustomDesignPageStepCardValue