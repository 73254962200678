import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from '../types/merchant.types';
import Button from './button';
import DashboardFormSection from './dashboard-form-section';
import AppFormReadonly from './form/app-form-readonly';
import DashboardSectionContainer from './dashboard-section-container';
import { useForm } from 'react-hook-form';
import AppFormInput from './form/app-form-input';
import { BankAccountCard } from './bank-account-card';

type Props = {
  manager: Employee,
};

function MemoComponent({ manager }: Props) {
  const { t } = useTranslation();

  const managerDetails = [
    { label: t("forms.field.name.label"), value: `${manager.firstName} ${manager.lastName}` },
    { label: t("forms.field.phone-number.label"), value: manager.phoneNumber },
    { label: t("forms.field.email.label"), value: manager.email }
  ];

  const managerForm = useForm<Employee>({
    mode: 'onTouched',
    defaultValues: manager
  });

  const onSubmitManager = useCallback((data: Employee) => {
    console.log('saving manager: ', data);
  }, []);

  const ManagerEditableInputs = memo(() => (
    <>
      <AppFormInput
        name="firstName"
        control={managerForm.control}
        label={t('forms.field.first-name.label')}
        validations={{ required: true }}
      />

      <AppFormInput
        name="lastName"
        control={managerForm.control}
        label={t('forms.field.last-name.label')}
        validations={{ required: true }}
      />

      <AppFormInput
        name="phoneNumber"
        control={managerForm.control}
        label={t('forms.field.phone-number.label')}
        validations={{ required: true, minLength: 5 }}
        type="text"
      />

      <AppFormInput
        name="email"
        control={managerForm.control}
        label={t('forms.field.email.label')}
        validations={{ required: true, minLength: 5 }}
        type="email"
      />
    </>
  ));

  return (
    <DashboardSectionContainer>
      <DashboardFormSection
        title={t('admin.account.manager.title')}
        onSave={managerForm.handleSubmit(onSubmitManager)}
        formIsValid={managerForm.formState.isValid}
        readonlyView={
          <div className="flex-column flex-gap-25">
            <section>
              {managerDetails.map((detail) => (
                <AppFormReadonly
                  key={detail.label}
                  label={detail.label}
                  value={detail.value}
                  className="mb-20"
                />
              ))}

              <div className="mt-20">
                <span className="form-label display-block mb-10">{t('forms.field.password.label')}</span>
                <Button label={t('layout.buttons.change-password')} theme="outline"/>
              </div>

              <AppFormReadonly
                key={t('admin.account.manager.id')}
                label={t('admin.account.manager.id')}
                value={manager.assignedManagerId}
                className="mt-20"
              />
            </section>
          </div>
        }
        editView={
          <div className="flex-column flex-gap-25">
            <section>
              <ManagerEditableInputs />

              <div className="mt-20">
                <span className="form-label display-block mb-10">{t('forms.field.password.label')}</span>
                <Button label={t('layout.buttons.change-password')} theme="outline"/>
              </div>

              <AppFormReadonly
                key={t('admin.account.manager.id')}
                label={t('admin.account.manager.id')}
                value={manager.assignedManagerId}
                className="mt-20"
              />
            </section>
          </div>
        }
      />
    </DashboardSectionContainer>
  );
}

export const AccountManagerCard = memo(MemoComponent);