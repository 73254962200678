import { Transaction } from '../../../types/merchant.types';

export const mockTransactions: Transaction[] = [
  {
    id: "T1",
    amount: 150,
    currency: "USD",
    shopId: "1",
    cardId: "1",
    date: "2023-08-15T14:23:00Z",
    reference: "12654345",
    user: {
      firstName: "John",
      lastName: "Doe",
    },
  },
  {
    id: "T2",
    amount: 250,
    currency: "USD",
    shopId: "2",
    cardId: "2",
    date: "2023-08-16T10:11:00Z",
    reference: "00004343",
    user: {
      firstName: "Jane",
      lastName: "Smith",
    },
  },
  {
    id: "T3",
    amount: 100,
    currency: "USD",
    shopId: "3",
    cardId: "3",
    date: "2023-08-17T08:45:00Z",
    reference: "24688642",
    user: {
      firstName: "Emily",
      lastName: "Johnson",
    },
  },
  {
    id: "T4",
    amount: 200,
    currency: "USD",
    shopId: "4",
    cardId: "4",
    date: "2023-08-18T12:34:00Z",
    reference: "13579975",
    user: {
      firstName: "Michael",
      lastName: "Williams",
    },
  },
  {
    id: "T5",
    amount: 75,
    currency: "USD",
    shopId: "5",
    cardId: "5",
    date: "2023-08-19T15:29:00Z",
    reference: "12908743",
    user: {
      firstName: "Sarah",
      lastName: "Brown",
    },
  },
  {
    id: "T6",
    amount: 300,
    currency: "USD",
    shopId: "6",
    cardId: "6",
    date: "2023-08-20T11:03:00Z",
    reference: "84847373",
    user: {
      firstName: "David",
      lastName: "Jones",
    },
  },
  {
    id: "T7",
    amount: 90,
    currency: "USD",
    shopId: "7",
    cardId: "7",
    date: "2023-08-21T09:47:00Z",
    reference: "29384756",
    user: {
      firstName: "Laura",
      lastName: "Garcia",
    },
  },
  {
    id: "T8",
    amount: 220,
    currency: "USD",
    shopId: "8",
    cardId: "8",
    date: "2023-08-22T14:59:00Z",
    reference: "56473829",
    user: {
      firstName: "James",
      lastName: "Martinez",
    },
  },
  {
    id: "T9",
    amount: 180,
    currency: "USD",
    shopId: "9",
    cardId: "9",
    date: "2023-08-23T16:41:00Z",
    reference: "12340986",
    user: {
      firstName: "Sophia",
      lastName: "Rodriguez",
    },
  },
  {
    id: "T10",
    amount: 130,
    currency: "USD",
    shopId: "10",
    cardId: "10",
    date: "2023-08-24T13:17:00Z",
    reference: "12129090",
    user: {
      firstName: "Daniel",
      lastName: "Hernandez",
    },
  },
];
