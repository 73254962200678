import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Merchant } from '../types/merchant.types';

export interface MerchantState {
  merchant: Merchant | undefined
}

const initialState: MerchantState = {
  merchant: undefined,
};
const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    saveMerchant: (state: MerchantState, action: PayloadAction<Merchant>) => {
      state.merchant = action.payload;
    }
  }
});

export const merchantReducer = merchantSlice.reducer;
export const merchantSliceActions = {
  saveMerchant: merchantSlice.actions.saveMerchant,
};