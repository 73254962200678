import { memo } from 'react';
import "./generic-table.scss";

type Props = {
  columns: { label: string, secondLineLabel?: string, className: string }[]
  className?: string,
  rows: React.ReactNode
}

function MemoComponent({ columns, className, rows }: Props) {
  return (
    <div className={`generic-table ${className}`}>
      <div className="t-header">
        { columns.length > 0 && (
          <div className="t-row">
            {columns.map((column) => (
              <div key={column.label} className={`t-cell ${column.className}`}>
                <span className="display-block">{column.label}</span>
                {column.secondLineLabel && <span className="display-block mt-5">{column.secondLineLabel}</span>}
              </div>
            ))}
          </div>
          )}
      </div>

      <div className="t-body">
        {rows}
      </div>
    </div>
  )
}

export const GenericTable = memo(MemoComponent);