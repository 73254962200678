import { PaymentStatus, PaymentUser } from "./checkout.types";
import './checkout-group.part.user-section.scss';
import { formatPrice } from "../../../../utils/number.utils";
import { dateService } from "../../../../services/date.service";



interface CheckoutGroupPartUserSectionProps {
    users: PaymentUser[];
    amount: number;
    timeLeft: number;
}



const CheckoutGroupPartUserSection: React.FC<CheckoutGroupPartUserSectionProps> = ({users, amount, timeLeft }) => {
    return (
        <div className="checkout-group-user-section flex-column width-100 flex-gap-20">

            <div className="header flex-row">
                <div className="user">User</div>
                <div className="status">Status</div>
                <div className="amount flex-row flex-align-end-center">Amount</div>
            </div>
            <div className="header-separator"></div>
            <div className="users-section flex-column flex-gap-10">
                {
                    users.map(user => (
                        <div className="user-order-details flex-column flex-gap-10">
                            <div className="flex-row">
                                <div className="user-details user flex-column flex-gap-5">
                                    <span className="full-name">{user.name}</span>
                                    <p className="email">Email: <span>{user.email}</span></p>
                                    <p className="phone">Phone: <span>{user.phone}</span></p>
                                </div>
                                <div className={`status ${user.status}`}>{user.status.toLocaleUpperCase()}</div>
                                <div className="amount-time amount flex-column flex-align-start-end  flex-gap-5">
                                    <span className="amount-value">{formatPrice(amount)}</span>
                                    <p style={{ visibility: 'hidden' }}>space</p>
                                    <span className="time-left">{user.status === PaymentStatus.PENDING && dateService.getHoursTimeLeftDisplay(timeLeft)}
                                        {user.status === PaymentStatus.PAID && dateService.getHoursTimeLeftDisplay(0)}</span>
                                </div>
                            </div>
                            <div className="separator"></div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CheckoutGroupPartUserSection;