import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppStore } from '../../store/store';
import { MerchantState } from '../../store/merchant.slice';
import "./merchant-menu.scss";

export enum MerchantPage {
  PROFILE = "merchant.menu.item.profile",
  USERS = "merchant.menu.item.users",
  SHOPS = "merchant.menu.item.shops",
  TEMPLATES = "merchant.menu.item.templates",
  STATISTICS = "merchant.menu.item.statistics",
}

const merchantMenuItems = [
  { icon: 'user', label: MerchantPage.PROFILE, path: 'profile' },
  { icon: 'group', label: MerchantPage.USERS, path: 'users' },
  { icon: 'shop', label: MerchantPage.SHOPS, path: 'shops' },
  { icon: 'credit-card', label: MerchantPage.TEMPLATES, path: 'templates' },
  { icon: 'pie-chart', label: MerchantPage.STATISTICS, path: 'statistics' },
]

function MemoComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const merchantState = useSelector<AppStore, MerchantState>(store => store.merchant);
  const merchant = merchantState.merchant;

  return (
    <div className="merchant-menu">
      <div className="menu-user">
        <div className="profile-image"></div>
        <span className="merchant-name">{merchant?.companyName}</span>
      </div>

      <div className="menu-items-container">
        {merchantMenuItems.map((item, idx) => (
          <div
            key={item.label}
            className={`menu-item ${location.pathname.includes(item.path) ? 'active' : ''}`}
            onClick={() => navigate(item.path)}
          >
            <span className={`menu-item-icon app-icon app-icon-${item.icon}`}></span>
            <span className="label">{t(item.label)}</span>
            <span className="app-icon app-icon-arrow-right"></span>
          </div>
        ))}
      </div>
    </div>
  )
}

export const MerchantMenu = memo(MemoComponent);