import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../../../components/page-title';
import PageSwitchToggle from '../../../../components/page-switch-toggle';
import ContentBox from '../../../../components/content-box';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { userSliceActions } from '../../../../store/user.slice';
import { BankCardType } from '../../../../models';
import LoginPageLoginForm, { LoginFormValue } from './login-page.login-form';
import { authService } from '../../../../services/auth.service';
import { apiService } from '../../../../services/api.service';
import { errorService } from '../../../../services/error.service';

enum AuthPage {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER'
}

const LoginPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pages = [{
    page: AuthPage.LOGIN,
    name: t('pages.login.tabs.login'),
    url: '/login'
  }, {
    page: AuthPage.REGISTER,
    name: t('pages.login.tabs.register'),
    url: '/register'
  }];

  const loginDummy =  async (data: LoginFormValue) => {
    try {
      setIsLoading(true);

      const loginResult = await authService.login(data);
      const responseUserData = await apiService.getUserDetails(loginResult.userId); 

    dispatch(userSliceActions.saveUser({
      ...responseUserData.data,
      phone: '+40777888999',
      verified: true,
      companyName: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac mi ut felis tincidunt dapibus.',
      profileImg: undefined,
      bankCards: [{
        card: {
          name: 'Razvan Burlacu',
          number: '6789',
          expiry: '2024-11-01',
          type: BankCardType.VISA
        },
        primary: true,
      }, {
        card: {
          name: 'Razvan Burlacu',
          number: '6789',
          expiry: '2024-11-01',
          type: BankCardType.VISA
        },
      }],
    }));

    navigate('/account/profile');
    } catch(e) {
      errorService.showApiError(e, 'Login failed!')
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page-outer">
      <div className="page-content">
        <PageTitle text={t('pages.login.title')}></PageTitle>

        <PageSwitchToggle pages={pages} activePage={AuthPage.LOGIN}></PageSwitchToggle>

        <ContentBox>
          <LoginPageLoginForm onSubmit={loginDummy} isLoading={isLoading} />

        </ContentBox>
      </div>
    </div>
  );
};

export default LoginPage;