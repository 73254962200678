import React from 'react';
import { Control, Controller, FieldValues, FieldPath, ValidationRule } from 'react-hook-form';
import AppFormElementLabel from './base/app-form-element-label';
import AppFormElementContainer from './base/app-form-element-container';
import Dropdown from './base/app-dropdown';
import { LabelValue } from '../../types/label-value.types';
import { Message } from 'react-hook-form/dist/types/errors';
import './app-form-dropdown.scss';

interface Validations {
  required: Message | ValidationRule<boolean>;
}


interface AppFormDropdownProps<TForm extends FieldValues,TName extends FieldPath<TForm>, TValue extends (string | number)> {
  control: Control<TForm>;
  name: TName;
  options: LabelValue<TValue>[];
  placeholder?: string;
  validations?: Partial<Validations>;
  label?: string;
  className?: string;
  onChange?: (option: string) => void;
}

const AppFormDropdown = <TForm extends FieldValues, TName extends FieldPath<TForm>,TValue extends (string | number)>({
  control,
  name,
  options,
  placeholder,
  label = '',
  className,
  onChange
}: AppFormDropdownProps<TForm, TName, TValue>) => {
  return (
    <AppFormElementContainer className={`app-form-dropdown ${className}`}>
      <AppFormElementLabel name={name} label={label} />

      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const handleChange = (value: TValue) => {
            field.onChange(value);
            if (onChange) {
              onChange(value?.toString());
            }
          }

          return (
            <Dropdown
              options={options}
              value={options.find(option => option.value === field.value)?.value}
              onChange={handleChange}
              placeholder={placeholder}
            />
          )}
        }
      />
    </AppFormElementContainer>
  );
};

export default AppFormDropdown;
