import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AppFormSectionTitle from "../../../../components/form/app-form-section-title";
import PageTitle from "../../../../components/page-title";
import { ShoppingCartState, shoppingCartActions } from "../../../../store/shopping-card.slice";
import { AppStore } from "../../../../store/store";
import './checkout-page.scss'
import GiftCard from "../../../../components/gift-card";
import { formatPrice } from "../../../../utils/number.utils";
import AppFormCounterInput from "../../../../components/form/app-form-counter-input";
import { FieldValues, useForm } from "react-hook-form";
import LineSeparator from "../../../../components/line-separator";
import { useState, useEffect } from "react";
import { UserState } from "../../../../store/user.slice";
import { useNavigate } from "react-router-dom";
import { CartItem } from "../../../../services/cart.service.types";
import { apiService } from "../../../../services/api.service";
import { Product } from "../../../../services/api.service.types";
import { errorService } from "../../../../services/error.service";
import AppInput from "../../../../components/form/base/app-input";
import Button from "../../../../components/button";
import ButtonIcon from "../../../../components/button-icon";
import CheckoutGroupPartUserSection from "./checkout-group.part.user-section";
import CheckoutGroupAddUserForm from "./checkout-group.part.add-user-form";
import CheckoutGroup from "./checkout-group";

export interface CustomCardValueFormValue extends FieldValues {
    [key: string]: number;
}

const CheckoutPage: React.FC = () => {
    const [cart, setCart] = useState<CartItem>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector<AppStore, UserState>(store => store.user);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [promoCode, setPromoCode] = useState();
    const form = useForm<CustomCardValueFormValue>({
        mode: 'onTouched',
        defaultValues: {}
    });

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    useEffect(() => {
        if (!userState.user) {
            return;
        }

        try {
            apiService.getCart(userState?.user?.id).then((response) => setCart(response.data))
        } catch (e) {

        }

    }, [userState?.user?.id]);

    useEffect(() => {
        form.reset();
        if (cart?.products) {
            cart.products.forEach((product) => {
                form.setValue(`quantity_${product.id}`, product.quantity);
            });
        }
    }, [cart?.products]);

    const handleQuantityChange = async (id: number, quantity: number) => {
        try {
            await apiService.updateProductQuantity(id, quantity);
            apiService.getCart(userState?.user!.id).then((response) => setCart(response.data))
        } catch (e) {
            errorService.showApiError(e);
        }
    };

    const handleRemoveProduct = async (itemId: number) => {
        await apiService.removeFromCart(itemId);
        apiService.getCart(userState?.user!.id).then((response) => setCart(response.data))
    }

    const handleAddPromoCode = async () => {
        if(!promoCode || !cart?.id) 
            return
        try {
        await apiService.addPromoCoreToCart(cart?.id, promoCode);
        } catch (e) {
            errorService.showApiError(e);
        }
    }

    
    const handleOrderByMyself = async () => {
        if(!cart) 
            return
        try {
            await apiService.createOrder(cart);
        } catch (e) {
            errorService.showApiError(e);
        }
    }


    const renderCartItemMobile = (product: Product) => (
        <div className="item-container flex-column flex-gap-20" key={product.id}>
            <div className="flex-column flex-gap-10 flex-align-center-center">
                <div className="card-container">
                    <GiftCard card={product.template} />
                </div>
                {product.recipientDetails && product.senderDetails &&
                    <div className="flex-column flex-align-start-start flex-gap-20" style={{ width: '260px' }}>
                        <p className="from-to">From: <span className="name-full">{product.senderDetails}</span></p>
                        <p className="from-to">To: <span className="name-full">{product.recipientDetails}</span></p>
                    </div>
                }
            </div>
            <div className="flex-row flex-align-center-center">
                <div className="quantity-value-container flex-row flex-gap-20">
                    <div className="quantity-container">
                        <h2>{`${product.quantity}x`}</h2>
                    </div>
                    <div className="flex-column title-value-container">
                        <span>{''}</span>
                        <span className="value">{formatPrice(product.amount)}</span>
                    </div>
                </div>
                <div className="icons-container flex-row flex-gap-20">
                    <span className="app-icon app-icon-heart" style={{ cursor: 'pointer' }}></span>
                    <span className="app-icon app-icon-delete" style={{ color: '#F15D60', cursor: 'pointer' }} onClick={() => handleRemoveProduct(product.id)}></span>
                </div>
            </div>
            <AppFormCounterInput
                control={form.control}
                name={`quantity_${product.id}`}
                min={1}
                max={100}
                step={1}
                onChange={(quantity) => handleQuantityChange(product.id, quantity)}
            />
        </div>
    )

    return (
        <div className="page-outer">
            <div className="page-content">
                <PageTitle text={'Checkout'}></PageTitle>
                <div className="checkout-page-content-container">
                    <div className="details-container flex-column flex-gap-20">
                        <CheckoutGroup/>
                    </div>
                    <div className="items-container">
                        <div className="item-container">
                            <div className="quantity-container hide-md">
                                <h2></h2>
                            </div>
                            <AppFormSectionTitle
                                title="Items"
                            />
                        </div>
                        {cart?.products?.map((product, index) => (
                            <>
                                {screenWidth > 700 ? (<div className="item-container flex-column flex-gap-20" key={product.id}>
                                    <div className="flex-row flex-gap-30">
                                        <div className="quantity-container">
                                            <h2>{`${product.quantity}x`}</h2>
                                        </div>
                                        <div className="card-container">
                                            <GiftCard card={product.template} />
                                        </div>
                                        <div className="flex-column title-value-container">
                                            <span>{''}</span>
                                            <span className="value">{formatPrice(product.amount)}</span>
                                        </div>
                                        <AppFormCounterInput
                                            control={form.control}
                                            name={`quantity_${product.id}`}
                                            min={1}
                                            max={100}
                                            step={1}
                                            onChange={(quantity) => handleQuantityChange(product.id, quantity)}
                                        />
                                        <div className="buttons-container">
                                            <span className="app-icon app-icon-heart" style={{ cursor: 'pointer' }}></span>
                                            <span className="app-icon app-icon-delete" style={{ color: '#F15D60', cursor: 'pointer' }} onClick={() => handleRemoveProduct(product.id)}></span>
                                        </div>
                                    </div>
                                    {product.recipientDetails && product.senderDetails &&
                                        <div className="flex-row flex-gap-30">
                                            <div className="quantity-container">
                                                <h2></h2>
                                            </div>
                                            <p className="from-to">From: <span className="name-full">{product.senderDetails}</span></p>
                                            <p className="from-to">To: <span className="name-full">{product.senderDetails}</span></p>
                                        </div>
                                    }
                                </div>) : renderCartItemMobile(product)
                                }
                                <LineSeparator />
                                {index == cart.products.length - 1 &&
                                    <div className="promocode-container flex-row flex-align-space-between-center">
                                        <div className="icon-input-container flex-row flex-align-start-center flex-gap-15">
                                            <span className="app-icon app-icon-tags"></span>
                                            <AppInput
                                                placeholder="Enter Promo Code"
                                                onChange={(value) => {
                                                    setPromoCode(value.target.value)
                                                }} />
                                        </div>
                                        <Button
                                            theme="accent"
                                            label='Use Promo'
                                            onClick={handleAddPromoCode}
                                        />
                                    </div>
                                }


                            </>
                        ))
                        }
                        {/* <LineSeparator /> */}
                        <div className="order-total-container flex-column flex-gap-10">
                            <div className="flex-row flex-align-space-between-center total">
                                <span>Subtotal:</span>
                                <span>{formatPrice(cart?.subtotal)}</span>
                            </div>
                            <div className="flex-row flex-align-space-between-center ">
                                <span className="width-45">Bloom Commision 5%:</span>
                                <span>{formatPrice(cart?.commissionPresent)}</span>
                            </div>
                            <div className="flex-row flex-align-space-between-center">
                                <span>VAT 18%:</span>
                                <span>{formatPrice(cart?.vatPresent)}</span>
                            </div>
                            <div className="flex-row flex-align-space-between-center">
                                <span>Promo Code:</span>
                                <span>{formatPrice(cart?.promoCode?.discountAmount)}</span>
                            </div>
                            <div className="flex-row flex-align-space-between-center total">
                                <span>Total:</span>
                                <span>{formatPrice(cart?.totalAmount)}</span>
                            </div>
                        </div>
                        <div className="buttons-container flex-row">
                            <div className="continue-shopping flex-1">
                                <Button
                                    theme="outline"
                                    label='Continue Shopping' />
                            </div>
                            <div className="payment-buttons-container flex-1 flex-row flex-gap-20 flex-align-end-center">
                                <ButtonIcon
                                    theme="secondary-accent"
                                    label='Group payment' 
                                    icon="add-to-cart"
                                    />
                                <ButtonIcon
                                    theme="accent"
                                    label='Pay by myself' 
                                    icon="add-to-cart"
                                    onClick={handleOrderByMyself}
                                    />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage;