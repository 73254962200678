import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { registerRouter } from './public/pages/register/register-router';
import RegisterPage from './public/pages/register/register-page';
import HomePage from './public/pages/home/home-page';
import AppLayout from './app-layout';
import MyAccountLayout from './my-account/my-account-layout';
import { myAccountRouter } from './my-account/my-account-router';
import AdminLayout from './admin/admin-layout';
import { adminRouter } from './admin/admin-router';
import LoginPage from './public/pages/login/login-page';
import { customDesignRouter } from './public/pages/create-custom-design/custom-design-router';
import CustomDesignLayout from './public/pages/create-custom-design/custom-design-page';
import CataloguePage from './public/pages/catalogue/catalogue-page';
import AccountCreatedPage from './public/pages/account-created/account-created-page';
import AssistancePage from './public/pages/assistance/assistance-page';
import { assistanceRouter } from './public/pages/assistance/assistance-router';
import CheckoutPage from './public/pages/checkout/checkout-page';
import MerchantLayout from './merchant/merchant-layout';
import { merchantRouter } from './merchant/merchant-router';

const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {path: '/', element: <HomePage />},
      {path: '/login', element: <LoginPage />},
      {
        path: '/register',
        element: <RegisterPage/>,
        children: registerRouter
      },
      {
        path: '/account',
        element: <MyAccountLayout />,
        children: myAccountRouter
      },
      {
        path: '/admin',
        element: <AdminLayout />,
        children: adminRouter
      },
      {
        path: '/merchant/:merchantId',
        element: <MerchantLayout />,
        children: merchantRouter
      },
      {
        path: '/e-gift-cards',
        element: <CataloguePage/>
      },
      {
        path: '/custom-design',
        element: <CustomDesignLayout/>,
        children: customDesignRouter
      },
      {
        path: '/account-created',
        element: <AccountCreatedPage/>,
      },
      {
        path: '/assistance',
        element: <AssistancePage/>,
        children: assistanceRouter
      }, {
       path: '/checkout',
       element: <CheckoutPage/>
      }
    ]
  },
]);

const AppRouter: React.FC = () => {
  return (
    <RouterProvider router={appRouter} />
  );
};

export default AppRouter;

