import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Shop } from '../../../types/merchant.types';
import { dateService } from '../../../services/date.service';
import shopImg from "./shop.png";
import { mockEmployees } from '../../../router/merchant/mockData';
import { useOutletContext } from 'react-router-dom';

type Props = {
  shop: Shop,
  onSelect: (shop: Shop) => void
};

function MemoComponent({ shop, onSelect }: Props) {
  const { t } = useTranslation();
  const { screenWidth } = useOutletContext<{ screenWidth: number }>();
  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    if (screenWidth >= 600) {
      setIsExtended(false);
    }
  }, [screenWidth]);

  const onToggleRow = () => {
    if (screenWidth < 600) {
      setIsExtended(!isExtended);
    }
  }

  const manager = mockEmployees.find(item => item.id === shop.managerId);

  return (
    <div className="shop-row">
      <div className="t-row" onClick={onToggleRow}>
        <div className="t-cell cell-name flex-row flex-align-start-center flex-gap-20">
          <img src={shopImg} alt="shop" className="shop-img display-block"/>
          <span className="shop-name display-block">{shop.name}</span>
        </div>
        <div className="t-cell cell-coordinates hide-xs">
          <span className="coordinate-lat display-block mb-5">Lat: {shop.address.latitude}</span>
          <span className="coordinate-long display-block">Long: {shop.address.longitude}</span>
        </div>
        <div className="t-cell cell-date hide-xs">
          <span>{dateService.formatBirthDate(shop.dateAdded)}</span>
        </div>
        <div className="t-cell cell-manager hide-xs">
          <span>{manager?.firstName} {manager?.lastName}</span>
        </div>
        <div className="t-cell cell-actions" onClick={() => onSelect(shop)}>
          <span>{t('layout.buttons.edit')}</span>
          <span className="app-icon app-icon-edit"></span>
        </div>
      </div>

      { isExtended && (
        <div className="more-details flex-row flex-align-space-between-center flex-gap-25 mb-20">
          <div>
            <div className="mb-10">
              <span className="bolded-text">{`${t("merchant.menu.item.manager")}: `}</span>
              <span>{manager?.firstName} {manager?.lastName}</span>
            </div>

            <div>
              <span className="bolded-text">{`${t('forms.field.date.label')}: `}</span>
              <span>{dateService.formatBirthDate(shop.dateAdded)}</span>
            </div>
          </div>

          <div>
            <div className="mb-10">
              <span className="bolded-text">{`${t('forms.field.latitude.label')}: `}</span>
              <span>{shop.address.latitude}</span>
            </div>

            <div className="">
              <span className="bolded-text">{`${t('forms.field.longitude.label')}: `}</span>
              <span>{shop.address.longitude}</span>
            </div>
          </div>
        </div>
      )}

      <div className="address-row">
        <span className="address-label">{t('forms.field.address.label')}: </span>
        <div className="address-details">
          <span className="key">{t('forms.field.street.label')}: </span>
          <span className="value">{shop.address.street},</span>
        </div>
        <div className="address-details">
          <span className="key">{t('forms.field.county.label')}: </span>
          <span className="value">{shop.address.county},</span>
        </div>
        <div className="address-details">
          <span className="key">{t('forms.field.city.label')}: </span>
          <span className="value">{shop.address.city},</span>
        </div>
        <div className="address-details">
          <span className="key">{t('forms.field.country.label')}: </span>
          <span className="value">{shop.address.country},</span>
        </div>
        <div className="address-details">
          <span className="key">{t('forms.field.postal-code.label')}: </span>
          <span className="value">{shop.address.postalCode}</span>
        </div>
      </div>

    </div>
  )
}

export const ShopRow = memo(MemoComponent);