import { memo } from 'react';

function MemoComponent() {
  return (
    <div className="profile-page">
      TODO: admin profile
    </div>
  );
}

export const ProfilePage = memo(MemoComponent);