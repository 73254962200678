import React from 'react';
import { Control, Controller, FieldValues, FieldPath, ControllerRenderProps, ValidationRule } from 'react-hook-form';
import './custom-form-radio-group.scss';
import AppFormElementContainer from '../../../../components/form/base/app-form-element-container';
import Dropdown from '../../../../components/form/base/app-dropdown';
import AppInput from '../../../../components/form/base/app-input';
import { LabelValue } from '../../../../types/label-value.types';

interface Validations {
    required: string | ValidationRule<boolean>;
    minLength: ValidationRule<number>;
    maxLength: ValidationRule<number>;
}

interface CustomFormRadioGroupProps<TForm extends FieldValues, TName extends FieldPath<TForm>> {
    control: Control<TForm>;
    name: TName;
    dropdownOptions: LabelValue[];
    dropdownPlaceholder?: string;
    dropdownValue?: string;
    dropdownText?: string;
    inputPlaceholder?: string;
    inputValue?: string;
    inputText?: string;
    validations?: Partial<Validations>;
}

const CustomFormRadioGroup = <TForm extends FieldValues, TName extends FieldPath<TForm>>({
    control,
    name,
    dropdownOptions,
    dropdownPlaceholder = "Select an option",
    dropdownValue,
    dropdownText,
    inputPlaceholder,
    inputValue,
    inputText,
    validations,
}: CustomFormRadioGroupProps<TForm, TName>) => {

    const handleDropdownChange = (
        field: ControllerRenderProps<TForm, TName>,
        dropdownValue: any
    ) => {
        const newValue = {
            ...field.value,
            selectedOption: 'dropdown',
            dropdownValue: dropdownValue,
        };
        field.onChange(newValue);
    };

    const handleInputChange = (
        field: ControllerRenderProps<TForm, TName>,
        inputValue: string
    ) => {

        const newValue = {
            ...field.value,
            selectedOption: 'input',
            inputValue: inputValue,
        };
        field.onChange(newValue);
    };

    const onSelect = (field: ControllerRenderProps<TForm, TName>, selectedCheckbox: string) => {
        const newValue = {
            ...field.value,
            selectedOption: selectedCheckbox,
        };
        field.onChange(newValue);
        field.onBlur();
    };

    return (
        <AppFormElementContainer className="custom-form-radio-group">
            <Controller
                name={name}
                control={control}
                rules={validations}
                render={({ field, fieldState }) => (
                    <>

                        <div className="radio-group-container">
                            <p className="radio-text">{dropdownText}</p>
                            <div className="radio-option-container"
                            >
                                <div className={`radio-container ${field.value?.selectedOption === 'dropdown' ? 'checked' : ''}`}
                                    onClick={() => onSelect(field, 'dropdown')}
                                >
                                    <div className="radio-checked"></div>
                                </div>
                                <div className="radio-content">

                                    <Dropdown
                                        options={dropdownOptions}
                                        value={field.value?.dropdownValue || dropdownValue}
                                        onChange={(selectedValue) => handleDropdownChange(field, selectedValue)}
                                        placeholder={dropdownPlaceholder}
                                    />
                                </div>
                            </div>

                            <div className="separator-container">
                                <div className="separator-line"></div>
                                <div className="separator-text">OR</div>
                                <div className="separator-line"></div>
                            </div>

                            <p className="radio-text">{inputText}</p>
                            <div className="radio-option-container"
                            >
                                <div className={`radio-container ${field.value?.selectedOption === 'input' ? 'checked' : ''}`}
                                    onClick={() => onSelect(field, 'input')}
                                >
                                    <div className="radio-checked"></div>
                                </div>
                                <div className="radio-content">
                                    <AppInput
                                        id="custom-message"
                                        placeholder={inputPlaceholder}
                                        value={field.value?.inputValue || inputValue}
                                        onChange={(e) => handleInputChange(field, e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <p className="form-element-error">{fieldState.error?.message || ' '}</p>
                    </>
                )}
            />
        </AppFormElementContainer>
    );
};

export default CustomFormRadioGroup;
