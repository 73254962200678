import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubtitleBicolor from '../../../../components/subtitle-bicolor';
import { MerchantsTable } from '../../../../components/merchants-table';
import { apiService } from '../../../../services/api.service';
import { Merchant } from '../../../../types/merchant.types';
import "./merchants-page.scss";

function MemoComponent() {
  const { t } = useTranslation();
  const [merchants, setMerchants] = useState<Merchant[]>([]);

  useEffect(() => {
    apiService.getAllCompanies({}).then((response) => {
      setMerchants(response.data);
    })
  }, []);

  return (
    <div className="merchants-page">
      <SubtitleBicolor leftText={t("merchant.list.label")} rightText='' />

      <MerchantsTable merchants={merchants} />
    </div>
  );
}

export const MerchantsPage = memo(MemoComponent);