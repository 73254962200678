import React from 'react';
import { RouteObject } from 'react-router/dist/lib/context';
import { ProfilePage } from './pages/profile/profile-page';
import { MerchantsPage } from './pages/merchants/merchants-page';
import { TemplatesPage } from './pages/templates/templates-page';
import { Navigate } from 'react-router-dom';

export const adminRouter: RouteObject[] = [
  {
    path: '',
    element: <Navigate to='profile' replace={true} />
  },
  {
    path: 'profile',
    element: <ProfilePage />
  },
  {
    path: 'merchants',
    element: <MerchantsPage />
  },
  {
    path: 'templates',
    element: <TemplatesPage />
  }
];
