import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Merchant } from "../../types/merchant.types";
import { MerchantRow } from './MerchantRow/MerchantRow';
import { GenericTable } from '../generic-table/generic-table';
import "./merchants-table.scss";

function MemoComponent({ merchants }: { merchants: Merchant[] }) {
  const { t } = useTranslation();

  const columns = [
    { label: t("merchant.menu.item.merchant"), className: "cell-merchant" },
    { label: t("merchant.menu.item.shops"), className: "cell-shops hide-sm" },
    { label: t("merchant.menu.item.users"), className: "cell-users hide-sm" },
    { label: t("merchant.menu.item.templates"), className: "cell-templates hide-sm" },
    { label: t("merchant.menu.item.manager"), className: "cell-manager hide-xs" },
    { label: t("merchant.menu.item.date-added"), className: "cell-date hide-xs" },
    { label: '', className: "cell-actions" },
  ];

  return (
    <GenericTable
      className="merchants-table"
      columns={columns}
      rows={
        <>
          {merchants.map((merchant) => (
            <MerchantRow merchant={merchant} key={merchant.companyId}/>
          ))}
        </>
      }
    />
  );
};

export const MerchantsTable = memo(MemoComponent);