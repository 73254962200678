import { memo, useState } from 'react';
import { mockShops } from '../../mockData';
import { ShopsTable } from '../../../../components/shops-table';
import { Shop } from '../../../../types/merchant.types';
import { ShopForm } from '../../../../components/shop-form';

function MemoComponent() {
  const [selectedShop, setSelectedShop] = useState<Shop>();

  return (
    <div className="tab-card-container">
      <ShopForm
        shop={selectedShop}
        onSave={() => setSelectedShop(undefined)}
        onCancel={() => setSelectedShop(undefined)}
      />
      <ShopsTable shops={mockShops} onSelect={setSelectedShop} />
    </div>
  )
}

export const MerchantShops = memo(MemoComponent);