import React, { useState } from 'react';
import './app-dropdown.scss';
import { LabelValue } from '../../../types/label-value.types';

interface DropdownProps<T = string> {
  options: LabelValue<T>[];
  value?: T;
  onChange: (value: T) => void;
  placeholder?: string;
}

const Dropdown = <T,>({ options, value, onChange, placeholder }: DropdownProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: LabelValue<T>) => {
    onChange(option.value);
    setIsOpen(false);
  };
  
  return (
    <div className="app-dropdown-container">
      <div className="dropdown-header" onClick={handleDropdownToggle}>
        {value ? options.find(option => option.value === value)?.label : placeholder}
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <div
              key={option.value?.toString()}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
