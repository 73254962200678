import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../../types/merchant.types';
import { TransactionRow } from './transaction-row/transaction-row';
import { GenericTable } from '../generic-table/generic-table';
import "./transactions-table.scss";

type Props = {
  transactions: Transaction[],
}

function MemoComponent({ transactions }: Props) {
  const { t } = useTranslation();
  const columns = [
    { label: t("forms.field.user.label"), className: 'cell-user' },
    { label: t("forms.field.value.label"), className: 'cell-value' },
    { label: t("forms.field.shop.label"), className: 'cell-shop hide-sm' },
    { label: t("forms.field.date.label"), className: 'cell-date' },
    { label: t("forms.field.transaction-no.label"), className: 'cell-transaction-no hide-xs' },
    { label: t("forms.field.invoice.label"), className: 'cell-invoice hide-sm' },
  ];

  return (
    <GenericTable
      className="transactions-table"
      columns={columns}
      rows={
        <>
          {transactions.map((transaction) => (
            <TransactionRow transaction={transaction} key={transaction.id} />
          ))}
        </>
      }
    />
  );
}

export const TransactionsTable = memo(MemoComponent);