import { memo, ReactNode } from 'react';
import "./pagination-wrapper.scss";

type Props = {
  children: ReactNode,
  currentPage: number,
  totalPages: number,
  onSelectPage: (page: number) => void,
  disabled?: boolean,
}

const PAGES_TO_SHOW = 5;

function MemoComponent({ children, currentPage, totalPages, onSelectPage, disabled }: Props) {
  const hasPrev = currentPage > 1;
  const hasNext = currentPage < totalPages;

  // calculate the limits based on the current page
  let startPage, endPage;
  const pageNumbers = [];

  if (totalPages <= PAGES_TO_SHOW) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = PAGES_TO_SHOW;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="pagination-wrapper">
      <div className={`container ${disabled ? 'disabled' : ''}`}>
        {children}
      </div>

      <div className="page-nav mt-20 flex-row flex-align-center-center flex-gap-20">
        { hasPrev && (
          <div className="nav-arrow first-page-arrow" onClick={() => onSelectPage(currentPage - 1)}>
            <span className="app-icon app-icon-arrow-left2 display-block"></span>
          </div>
        )}

        <div className="flex-row flex-align-center-center flex-gap-5">
          {pageNumbers.map(page => (
            <div
              key={page}
              className={`page-number ${page === currentPage ? 'selected-page' : ''}`}
              onClick={() => onSelectPage(page)}
            >
              {page}
            </div>
          ))}
        </div>

        { hasNext && (
          <div className="nav-arrow last-page-arrow" onClick={() => onSelectPage(currentPage + 1)}>
            <span className="app-icon app-icon-arrow-right2 display-block"></span>
          </div>
        )}
      </div>
    </div>
  );
}

export const PaginationWrapper = memo(MemoComponent);