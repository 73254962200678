import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/store';
import { UserState } from '../../store/user.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./admin-menu.scss";

export enum AdminPage {
  MERCHANTS = 'admin.menu.item.merchants',
  PROFILE = 'admin.menu.item.profile',
  TEMPLATES = 'admin.menu.item.templates',
}

const adminMenuItems = [
  { icon: 'user', label: AdminPage.PROFILE, path: 'profile' },
  { icon: 'group', label: AdminPage.MERCHANTS, path: 'merchants' },
  { icon: 'credit-card', label: AdminPage.TEMPLATES, path: 'templates' },
]

function MemoComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const userState = useSelector<AppStore, UserState>(store => store.user);

  return (
    <div className="admin-menu">
      <div className="menu-user">
        <div className="profile-image"></div>
        <span className="user-name">{userState.user?.firstName + ' ' + userState.user?.lastName}</span>
        {userState.user?.verified && (
          <span className="verified">V</span>
        )}
      </div>

      <div className="menu-items-container">
        {adminMenuItems.map((item, idx) => (
            <div
              className={`menu-item ${location.pathname.includes(item.path) ? 'active' : ''}`}
              onClick={() => navigate(item.path)}
              key={item.label}
            >
              <span className={`menu-item-icon app-icon app-icon-${item.icon}`}></span>
              <span className="label">{t(item.label)}</span>
              <span className="app-icon app-icon-arrow-right"></span>
            </div>
        ))}
      </div>
    </div>
  )
}

export const AdminMenu = memo(MemoComponent);