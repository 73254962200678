import { memo } from 'react';
import { GiftCardModel } from '../models';
import "./template-card.scss";

type Props = {
  template: GiftCardModel,
  onSelect?: (card: GiftCardModel) => void
}

function MemoComponent({ template, onSelect }: Props) {
  return (
    <div
      className={`template-card ${onSelect ? "selectable": ''}`}
      style={{
        backgroundColor: template.backgroundColor || undefined,
        backgroundImage: `url(${template.backgroundImage})`
      }}
      onClick={onSelect ? () => onSelect(template) : undefined}
    >
    </div>
  )
}

export const TemplateCard = memo(MemoComponent);
