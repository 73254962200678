import dayjs from 'dayjs';

class DateService {
  getYear(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${dateObj.getFullYear()}`;
  }

  getMonth(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${(dateObj.getMonth() + 1)}`.padStart(2, '0');
  }

  getDay(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${dateObj.getDate()}`.padStart(2, '0');
  }

  getDateString(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    return dayjs(date).format('YYYY-MM-DD');
  }

  formatBirthDate(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateString = date instanceof Date ? date.toISOString() : date;
    return dayjs(dateString).format('YYYY-MM-DD');
  }

  getDateDisplay(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    return dayjs(date).format('DD/MM/YYYY');
  }
  
  getTimeDisplay(date?: Date): string | undefined {
    if (!date) {
      return;
    }

    return dayjs(date).format('HH:mm');
  }

  getExpirationShort(date?: Date | string): string | undefined {
    if (!date) {
      return;
    }

    const dateObj = date instanceof Date ? date : new Date(date);
    return `${this.getMonth(dateObj)}/${this.getYear(dateObj)}`;
  }
  
  getHoursTimeLeftDisplay(timeSeconds: number) {
    const {hours, minutes, seconds} = this.getTimeLeftHuman(timeSeconds);

    return `${hours.toString().padStart(2, '0')} h : ${minutes.toString().padStart(2, '0')} min : ${seconds.toString().padStart(2, '0')} sec`;
  }

  getTimeLeftHuman(timeSeconds: number) {
    let minutes = Math.floor(timeSeconds / 60);
    const seconds = timeSeconds % 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    return {hours, minutes, seconds};
  }

  isUnder18(date: Date | string): boolean {
    const maxBirthDate = dayjs().subtract(18, 'years');
    return dayjs(date).isBefore(maxBirthDate);
  }
}

const dateService = new DateService();
export { dateService };