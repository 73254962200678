import { memo, useCallback, useEffect, useState } from 'react';
import { Transaction } from '../../../types/merchant.types';
import { mockShops } from '../../../router/merchant/mockData';
import { dateService } from '../../../services/date.service';
import { currencyAmountFormatted } from '../../../utils/numeric-utils';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  transaction: Transaction,
}

function MemoComponent({ transaction }: Props) {
  const { t } = useTranslation();
  const { screenWidth } = useOutletContext<{ screenWidth: number }>();
  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    if (screenWidth >= 960) {
      setIsExtended(false);
    }
  }, [screenWidth]);

  const onToggleRow = () => {
    if (screenWidth < 960) {
      setIsExtended(!isExtended);
    }
  }

  const getShopById = useCallback(
    (id: string) => mockShops.find(item => item.id === id),
    []
  );

  return (
    <div className="transaction-row">
      <div className="t-row" onClick={onToggleRow}>
        <div className="t-cell cell-user">
          <span className="display-block mb-5">{transaction.user.firstName}</span>
          <span className="display-block">{transaction.user.lastName}</span>
        </div>
        <div className="t-cell cell-value">
          <span>{currencyAmountFormatted(transaction.amount, transaction.currency)}</span>
        </div>
        <div className="t-cell cell-shop hide-sm">
          <span>{getShopById(transaction.shopId)?.name || 'Online '}</span>
        </div>
        <div className="t-cell cell-date">
          <span>{dateService.formatBirthDate(transaction.date)}</span>
        </div>
        <div className="t-cell cell-transaction-no hide-xs">
          <span>{transaction.reference}</span>
        </div>
        <div className="t-cell cell-invoice hide-sm">
          <span className="app-icon app-icon-download"/>
        </div>
      </div>

      { isExtended && (
        <div className="more-details flex-row flex-align-space-between-center flex-gap-25">
          <div>
            <div className="mb-10 mt-10">
              <span className="bolded-text">{`${t('forms.field.shop.label')}: `}</span>
              <span>{getShopById(transaction.shopId)?.name || 'Online '}</span>
            </div>

            <div className="reference mb-10">
              <span className="bolded-text">{`${t('forms.field.transaction-no.label')}: `}</span>
              <span>{transaction.reference}</span>
            </div>
          </div>

          <div>
            <span className="app-icon app-icon-download"/>
          </div>
        </div>
      )}
    </div>
  )
}

export const TransactionRow = memo(MemoComponent);