import { memo } from 'react';
import { useSelector } from 'react-redux';
import { AccountManagerCard } from '../../../../components/account-manager-card';
import { AccountDocumentsCard } from '../../../../components/account-documents-card';
import { MerchantState } from '../../../../store/merchant.slice';
import { AppStore } from '../../../../store/store';
import { mockEmployees, mockCompany } from '../../mockData';
import "./merchant-profile.scss";

function MemoComponent() {
  const merchantState = useSelector<AppStore, MerchantState>(store => store.merchant);
  const merchant = merchantState.merchant;

  if (!merchant) return null;

  const manager = mockEmployees[0];
  const company = {
    ...mockCompany,
    name: merchant.companyName,
  }

  return (
    <div className="merchant-profile dashboard-page-content flex-wrap">
      <AccountManagerCard manager={manager}/>
      <AccountDocumentsCard company={company}/>
    </div>
  )
}

export const MerchantProfile = memo(MemoComponent);