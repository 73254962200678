import React, { memo, useEffect, useState } from 'react';
import PageTitle from '../../components/page-title';
import { useTranslation } from 'react-i18next';
import { AdminMenu } from './admin-menu';
import { Outlet } from 'react-router-dom';
import "./admin-layout.scss";

function MemoComponent() {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="admin-layout page-outer">
      <div className="page-content">
        <PageTitle text={t('my-account.layout.title')}></PageTitle>

        <div className="admin-router">
          <AdminMenu/>
          <div className="admin-page">
            <Outlet context={{ screenWidth }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const AdminLayout = memo(MemoComponent);
export default AdminLayout;