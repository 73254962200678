import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { authService } from './services/auth.service';
import { userSliceActions } from './store/user.slice';
import { BankCardType } from './models';
import { useDispatch } from 'react-redux';
import { apiService } from './services/api.service';
import { Toast } from 'primereact/toast';
import { errorService } from './services/error.service';

const AppInit: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    if (toastRef.current) {
      errorService.setToastRef(toastRef.current);
    }
    apiService.attachDispatchToAxiosApi(dispatch);
    authService.getAuthenticatedUser()
      .then(async user => {
        if (user) {
          const response = await apiService.getUserDetails(user.userId)

          dispatch(userSliceActions.saveUser({
            ...response.data,
            companyName: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac mi ut felis tincidunt dapibus.',
            profileImg: undefined,
            bankCards: [{
              card: {
                name: 'Razvan Burlacu',
                number: '6789',
                expiry: '2024-11-01',
                type: BankCardType.VISA,
              },
              primary: true,
            }, {
              card: {
                name: 'Razvan Burlacu',
                number: '6789',
                expiry: '2024-11-01',
                type: BankCardType.MASTERCARD
              }
            }],
          }
          ))

        }
      });
  }, []);

  return <div>
    <Toast ref={toastRef} />
    {children}
  </div>;
}

export default AppInit;