import { memo } from 'react';
import { Shop } from '../../types/merchant.types';
import { ShopRow } from './shop-row/shop-row';
import { GenericTable } from '../generic-table/generic-table';
import "./shops-table.scss";

type Props = {
  shops: Shop[],
  onSelect: (shop: Shop) => void,
}
function MemoComponent({ shops, onSelect }: Props) {
  return (
    <GenericTable
      className="shops-table"
      columns={[]}
      rows={
        <>
          {shops.map((shop) => <ShopRow shop={shop} key={shop.id} onSelect={onSelect} /> )}
        </>
      }
    />
  );
}

export const ShopsTable = memo(MemoComponent);