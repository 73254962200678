export interface PaymentUser {
    name: string;
    email: string;
    phone: string;
    status: PaymentStatus;
  }
  
  export interface PaymentGroup {
    id: number;
    name: string;
    users: PaymentUser[];
  }
  
  export enum PaymentStatus {
    PENDING = 'pending',
    PAID = 'paid',
  }